$laptop_ls: 'only screen and (min-width: 320px) and (max-width: 1919px)';
$laptop_l: '(max-width: 1440px)';
$laptop_m: '(max-width: 1366px)';
$laptop_m_two: '(max-width: 1280px)';
$laptop_m_three: '(max-width: 1200px)';
$laptop_m_four: '(max-width: 1199px)';
$laptop_m_five: '(max-width: 1024px)';
$laptop_m_six: '(min-width: 1200px)';
$medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$tab_device-and-mobile: 'only screen and (min-width: 320px) and (max-width: 990px)';
$tab_device_two: '(max-width: 768px)';
$tab_device_three: '(max-width: 991px)';
$tab_device_four: '(min-width: 991px)';
$large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
$large_mobile_two: ' (max-width: 600px)';
$large_mobile_three: ' (max-width: 575px)';
$large_mobile_four: ' (max-width: 480px)';
$small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
$small_mobile_one: ' (max-width: 425px)';
$small_mobile_two: '(max-width: 400px)';
$small_mobile_three: '(max-width: 320px)';
$small_mobile_four: '(max-width: 375px)';
$device-1600: '(max-width: 1600px)';
$device-767: '(max-width: 767px)';
$device-992: '(max-width: 992px)';
$device-767: '(max-width: 767px)';
$device-481: '(max-width: 481px)';
$device-384: '(max-width: 384px)';





