/*
	Template Name: Aduca - Learning & Online Education HTML Template
	Version: 1.0
	Author: TechyDevs
	Author Email: contact@techydevs.com
	Description: This is a Learning & Online Education HTML Template

*/
/*------------------------------------------------------------------
[Table of contents]
	+ General
		- Global Styles
		- general Styles
		- Preloader
		- Back To Top
		- theme-btn
		- section-heading
		- Move bg
		- Move bg 2
		- section divider
	+ Header
		- Logo
		- Category
		- Seach form
		- Main Navigation
		- Dropdown
		- Offcanvas menu
		- Admission
	+ Content
		+ Main Files
			- index.html
			- home-2.html
		+ Banner Area
			- Breadcrumb
	    + About
	        - About us
	    + Courses
	        - Course Grid
	        - Course Detail
		+ Pages
			- Teacher
			- Teacher Detail
			- FAQs
			- Admission
			- Gallery
			- Pricing Table
			- Sing up
			- Log in
			- Error 404 page
		+ Blog
		    - Blog Grid
		    - Blog Single
		+ Contact
		    - Contact
	+ Footer
		+ Top Footer
			- Logo
			- Company
			- Courses
			- Mobile app
		+ Bottom Footer
		    - Copyright
		    - Language Select
	+ Responsive Design Styles
            - $laptop_ls: 'only screen and (min-width: 320px) and (max-width: 1919px)';
            - $laptop_l: '(max-width: 1440px)';
            - $laptop_m: '(max-width: 1366px)';
            - $laptop_m_two: '(max-width: 1280px)';
            - $laptop_m_three: '(max-width: 1200px)';
            - $laptop_m_four: '(max-width: 1199px)';
            - $laptop_m_five: '(max-width: 1024px)';
            - $laptop_m_six: '(min-width: 1200px)';
            - $medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
            - $tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
            - $tab_device-and-mobile: 'only screen and (min-width: 320px) and (max-width: 990px)';
            - $tab_device_two: '(max-width: 768px)';
            - $tab_device_three: '(max-width: 991px)';
            - $tab_device_four: '(min-width: 991px)';
            - $large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
            - $large_mobile_two: ' (max-width: 600px)';
            - $large_mobile_three: ' (max-width: 575px)';
            - $large_mobile_four: ' (max-width: 480px)';
            - $small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
            - $small_mobile_one: ' (max-width: 425px)';
            - $small_mobile_two: '(max-width: 400px)';
            - $small_mobile_three: '(max-width: 320px)';
            - $small_mobile_four: '(max-width: 375px)';
            - $device-1600: '(max-width: 1600px)';
            - $device-767: '(max-width: 767px)';
            - $device-992: '(max-width: 992px)';
            - $device-767: '(max-width: 767px)';
            - $device-481: '(max-width: 481px)';
            - $device-384: '(max-width: 384px)';
-------------------------------------------------------------------*/
@import "plugin";
@import "mixin";
@import "responsive";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
a:hover,
a:focus{
  text-decoration: none;
}
input:focus {
  outline: none;
}
h1, h2, h3, h4, h5, h6 {
  color: $theme-color;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
body {
  font-size: 16px;
  line-height: 22px;
  font-family: $theme-font;
  color: $theme-color-4;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/*---------------- container ----------------*/
.container {
  max-width: 1200px;
}

@media (min-width:576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width:768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width:992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width:1200px) {
  .container {
    max-width: 1200px;
  }
}

/*-================== loading-area ==============-*/
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: $white;
  .cssload-loader {
    width: 97px;
    height: 19px;
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    > div {
      content: "";
      width: 12px;
      height: 12px;
      @include border-radius(100%);
      position: absolute;
      animation: cssload-shift 2.3s linear infinite;
      -o-animation: cssload-shift 2.3s linear infinite;
      -ms-animation: cssload-shift 2.3s linear infinite;
      -webkit-animation: cssload-shift 2.3s linear infinite;
      -moz-animation: cssload-shift 2.3s linear infinite;
      background-color: $theme-color-2;
      &:nth-of-type(1) {
        animation-delay: -0.46s;
        -o-animation-delay: -0.46s;
        -ms-animation-delay: -0.46s;
        -webkit-animation-delay: -0.46s;
        -moz-animation-delay: -0.46s;
      }
      &:nth-of-type(2) {
        animation-delay: -0.92s;
        -o-animation-delay: -0.92s;
        -ms-animation-delay: -0.92s;
        -webkit-animation-delay: -0.92s;
        -moz-animation-delay: -0.92s;
      }
      &:nth-of-type(3) {
        animation-delay: -1.38s;
        -o-animation-delay: -1.38s;
        -ms-animation-delay: -1.38s;
        -webkit-animation-delay: -1.38s;
        -moz-animation-delay: -1.38s;
      }
      &:nth-of-type(4) {
        animation-delay: -1.84s;
        -o-animation-delay: -1.84s;
        -ms-animation-delay: -1.84s;
        -webkit-animation-delay: -1.84s;
        -moz-animation-delay: -1.84s;
      }
    }
  }
}
/*---------------- cssload-shift ----------------*/
@keyframes cssload-shift {
  0% {
    left: -37px;
    opacity: 0;
  }
  10% {
    left: 0;
    opacity: 1;
  }
  90% {
    left: 62px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}

@-o-keyframes cssload-shift {
  0% {
    left: -37px;
    opacity: 0;
  }
  10% {
    left: 0;
    opacity: 1;
  }
  90% {
    left: 62px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}

@-ms-keyframes cssload-shift {
  0% {
    left: -37px;
    opacity: 0;
  }
  10% {
    left: 0;
    opacity: 1;
  }
  90% {
    left: 62px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}

@-webkit-keyframes cssload-shift {
  0% {
    left: -37px;
    opacity: 0;
  }
  10% {
    left: 0;
    opacity: 1;
  }
  90% {
    left: 62px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}

@-moz-keyframes cssload-shift {
  0% {
    left: -37px;
    opacity: 0;
  }
  10% {
    left: 0;
    opacity: 1;
  }
  90% {
    left: 62px;
    opacity: 1;
  }
  100% {
    left: 100px;
    opacity: 0;
  }
}
/*---------------- theme-btn ----------------*/
.theme-btn {
  @extend %theme-btn;
  &:after {
    @extend %theme-btn-after;
  }
  &:hover {
    color: $white;
    &:after {
      width: 100%;
      left: 0;
      @include transform(skewX(0));
      opacity: .2;
    }
  }
}
.theme-btn2 {
  @include border-radius(30px);
}
/*---------------- section-heading ----------------*/
.section-heading {
  position: relative;
  z-index: 1;
  .section__meta {
    display: inline-block;
    text-transform: uppercase;
    font-size: $default-font-3;
    color: $theme-color-2;
    background-color: rgba(81, 190, 120, 0.1);
    padding: 5px 10px;
    @include border-radius(4px);
    letter-spacing: 0.6px;
    margin-bottom: 5px;
    font-weight: $font-weight-regular;
  }
  .section__title {
    font-size: $primary-font;
    font-weight: $font-weight-semi-bold;
    @media #{$large_mobile_three} {
     font-size: $display-6;
    }
  }
  .section__title2,
  .section__meta2{
    color: $white;
  }
  .section__meta2 {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .section__meta3 {
    @include border-radius(30px);
    padding: 6px 15px;
  }
  .section__desc {
    line-height: 28px;
    margin-bottom: 34px;
  }
  .section__desc2 {
    font-size: $display-11;
    font-weight: $font-weight-medium;
  }
  .section__divider {
    display: inline-block;
    position: relative;
    height: 5px;
    @include border-radius(30px);
    background-color: $theme-color-2;
    width: 90px;
    margin-top: 18px;
    margin-bottom: 18px;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: -1.1px;
      height: 7px;
      width: 8px;
      background-color: $white;
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-name: MOVE-BG;
      animation-name: MOVE-BG;
    }
  }
  .section__divider2 {
    background-color: rgba(255, 255, 255, 0.1);
    &:after {
      background-color: $white;
    }
  }
}
/*---------------- MOVE-BG----------------*/
@-webkit-keyframes MOVE-BG {
   from {
     -webkit-transform: translateX(0);
     transform: translateX(0);
   }
   to {
     -webkit-transform: translateX(88px);
     transform: translateX(88px);
   }
 }
@-webkit-keyframes MOVE-BG {
  from {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -ms-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}
/*---------------- MOVE-BG 2 ----------------*/
@-webkit-keyframes MOVE-BG2 {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(48px);
    transform: translateX(48px);
  }
}
@-webkit-keyframes MOVE-BG2 {
  from {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -ms-transform: translateX(48px);
    transform: translateX(48px);
  }
}

@keyframes MOVE-BG2 {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(48px);
    transform: translateX(48px);
  }
}
/*---------------- section-divider ----------------*/
.section-divider {
  position: relative;
  height: 2px;
  background-color: rgba(127, 136, 151, 0.1);
}
.new-page-badge {
  display: inline-block;
  padding: 1px 6px 0 6px;
  background-color: $theme-color-2;
  color: $white;
  font-size: 11px;
  @include border-radius(4px);
  float: right;
  font-weight: $font-weight-semi-bold;
}
/*====================================================
    input-number-group
 ====================================================*/
.input-number-group {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }
  .input-group-button {
    line-height: 35px;
  }
  .input-number {
    padding: 0 12px;
    outline: none;
    display: block;
    text-align: center;
  }
  .input-number,
  .input-number-decrement,
  .input-number-increment {
    border: 1px solid rgba(127, 136, 151, 0.2);
    height: 35px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: $font-weight-semi-bold;
    font-size: $display-13;
    @include border-radius(30px);
    color: $theme-color;
  }
  .input-number-decrement,
  .input-number-increment {
    display: inline-block;
    width: 35px;
    text-align: center;
    cursor: pointer;
    @include border-radius(50%);
    @include transition(0.3s);
    &:hover {
      background-color: $theme-color-2;
      color: $white;
      border-color: $theme-color-2;
    }
  }
  .input-number-decrement {
    margin-right: 0.5rem;
    margin-left: 1rem;
  }
  .input-number-increment {
    margin-left: 0.5rem;
  }
}
/*======== select-option =======*/
.select-option {
  position: relative;
  .form-icon {
    position: absolute;
    top: 13px;
    left: 20px;
  }
  select {
    width: 100%;
    border: 1px solid rgba(127, 136, 151, 0.2);;
    padding: 15px 20px 15px 50px;
    display: block;
    margin-bottom: 20px;
    color: rgba(127, 136, 151, 0.6);
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    cursor: pointer;
    outline: none;
    @include border-radius(4px);
    background-color: $white;
    &:focus {
      border-color: rgba(127, 136, 151, 0.5);
    }
  }
  &:after {
    position: absolute;
    content: "\f110";
    top: 12px;
    right: 13px;
    font-family: "LineAwesome";
    color: $theme-color-4;
    pointer-events: none;
    display: inline-block;
  }
}
/*-===============================
    header-menu-area
===============================-*/
.header-menu-area {
  position: relative;
  z-index: 1023;
  background-color: $white;
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
    @media #{$device-1600} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .header-top {
    border-bottom: 1px solid rgba(127, 136, 151, 0.1);
    padding-right: 185px;
    padding-left: 185px;
    @media #{$device-1600} {
      padding-right: 0;
      padding-left: 0;
    }
    .col-lg-6 {
      @media #{$tab_device} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media #{$tab_device_two} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .header-widget {
      ul {
        height: 45px;
        @media #{$tab_device_two} {
          -ms-flex-pack: center !important;
          justify-content: center !important;
        }
        @media #{$small_mobile_three} {
          display: block !important;
          height: 100%;
        }
        li {
          margin-right: 10px;
          display: inline-block;
          &:last-child {
            margin-right: 0;
          }
          a {
            color: $theme-color;
            @include transition(0.3s);
            &:hover {
              color: $theme-color-2;
            }
          }
          .la {
            color: $theme-color-2;
          }
          .fa {
            width: 30px;
            height: 30px;
            line-height: 30px;
            @include border-radius(50%);
            background-color: rgba(81, 190, 120, 0.1);
            color: $theme-color-2;
            text-align: center;
            @include transition(0.3s);
            &:hover {
              color: $white;
              background-color: $theme-color-2;
            }
          }
        }
        &.contact-info {
          li {
            height: 45px;
            border-right: 1px solid rgba(127, 136, 151, 0.1);
            padding-right: 15px;
            padding-left: 15px;
            padding-top: 10px;
            margin-right: 15px;
            @media #{$tab_device_two} {
              border-right: 0;
              height: auto;
              padding-right: 0;
              padding-top: 0;
            }
            @media #{$small_mobile_three} {
              margin-right: 0;
              padding-right: 0;
              height: auto;
            }
            span {
              margin-right: 2px;
            }
            &:first-child,
            &:last-child {
              padding-left: 0;
            }
            &:last-child {
              @media #{$large_mobile} {
                margin-right: 0;
                padding-right: 0;
              }
              @media #{$small_mobile} {
                margin-right: 0;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
    .header-widget1 {
      @media #{$small_mobile_three} {
        text-align: center;
      }
    }
    .header-widget2 {
      height: 45px;
      @media #{$large_mobile} {
        height: auto;
        padding-bottom: 10px;
      }
      @media #{$small_mobile_three} {
        height: auto;
        margin-top: 10px;
      }
      .header-right-info {
        @media #{$small_mobile_three} {
          width: 100%;
        }
        ul {
          border-right: 1px solid rgba(127, 136, 151, 0.1);
          padding-right: 15px;
          padding-left: 15px;
          @media #{$tab_device_two} {
            border-right: 0;
            padding-right: 0;
            padding-left: 0;
          }
          @media #{$large_mobile} {
            border-right: 0;
            padding-right: 0;
            padding-left: 0;
          }
          @media #{$small_mobile_three} {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
          }
          li {
            margin-right: 5px;
            &:last-child {
              margin-right: 0;
            }
          }
          &.user-action {
            padding-right: 0;
            border-right: none;
            @media #{$small_mobile_four} {
              padding-left: 0;
            }
          }
          &.user-cart {
            padding-right: 0;
            padding-left: 0;
            @media #{$small_mobile_three} {
             text-align: right;
            }
            li {
              position: relative;
              .user-cart-btn {
                font-size: $display-10;
                cursor: pointer;
                padding: 7px 15px 13px 15px;
                @media #{$small_mobile_three} {
                 padding-right: 0;
                }
              }
              .dropdown-menu-item {
                position: absolute;
                z-index: 4;
                left: -225px;
                width: 280px;
                height: auto;
                top: 45px;
                padding: 25px;
                background-color: $white;
                @include border-radius(4px);
                @include transition(0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275));
                @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
                text-align: center;
                opacity: 0;
                visibility: hidden;
                @include transform(scale(0.8));
                @media #{$large_mobile_four} {
                  left: -125px;
                }
                @media #{$small_mobile_three} {
                  left: -240px;
                }
                li {
                  margin-right: 0;
                  margin-bottom: 15px;
                  line-height: 26px;
                  font-weight: $font-weight-medium;
                  color: $theme-color;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  .theme-btn {
                    color: $white;
                    font-size: $default-font-3;
                    line-height: 42px;
                    padding: 0 15px;
                  }
                }
                &:after {
                  top: -7px;
                  content: "";
                  position: absolute;
                  background-color: $white;
                  right: 20px;
                  width: 13px;
                  height: 13px;
                  @include transform(rotate(45deg));
                  @include box-shadow(-2px -2px 10px -5px rgba(0,0,0,.2));
                  @media #{$large_mobile_four} {
                    right: 120px;
                  }
                  @media #{$small_mobile_three} {
                    right: 10px;
                  }
                }
              }
              .shopping-cart-list {
                text-align: left !important;
                width: 315px !important;
                left: -259px !important;
                li {
                  border-bottom: 1px solid $color;
                  padding-bottom: 20px;
                  margin-bottom: 20px !important;
                  display: block !important;
                  &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                    margin-bottom: 0 !important;
                  }
                }
                .cart-item {
                  display: -webkit-flex;
                  display: -moz-flex;
                  display: -ms-flex;
                  display: flex;
                  -ms-flex-align: center;
                  align-items: center;
                  img {
                    width: 65px;
                    height: 65px;
                    margin-right: 15px;
                    @include border-radius(4px);
                  }
                  .item__info {
                    a {
                      display: block;
                      color: $theme-color;
                      font-weight: $font-weight-medium;
                      text-transform: capitalize;
                      line-height: 20px;
                      padding-bottom: 5px;
                      &:hover {
                        color: $theme-color-2;
                      }
                    }
                    .item__price {
                      font-size: $default-font;
                      color: $theme-color;
                      font-weight: $font-weight-semi-bold;
                      .item__before-price {
                        margin-left: 3px;
                        color: $theme-color-4;
                        text-decoration: line-through;
                      }
                    }
                  }
                }
                .cart-info {
                  p {
                    margin-bottom: 20px;
                  }
                  .item__price {
                    font-size: $display-13;
                    color: $theme-color;
                    font-weight: $font-weight-semi-bold;
                    .item__before-price {
                      margin-left: 3px;
                      color: $theme-color-4;
                      text-decoration: line-through;
                    }
                  }
                  .theme-btn {
                    display: block;
                    text-align: center !important;
                  }
                }
              }
              &:hover {
                .dropdown-menu-item {
                  opacity: 1;
                  visibility: visible;
                  @include transform(scale(1));
                }
              }
            }
          }
        }
        .social-info {
          @media #{$small_mobile_three} {
            display: none !important;
          }
        }
      }
      .header-right-info.d-flex {
        @media #{$small_mobile_three} {
          flex-direction: row-reverse;
        }
      }
    }
    .header-widget2.d-flex {
      @media #{$small_mobile_three} {
        display: block !important;
      }
    }
    .header-widget2.justify-content-end {
      @media #{$tab_device_two} {
        -ms-flex-pack: center !important;
        justify-content: center !important;
      }
    }
  }
  .header-menu-content {
    padding-right: 185px;
    padding-left: 185px;
    @include transition(0.5s);
    background-color: $white;
    @media #{$device-1600} {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .main-menu-content {
    height: 100px;
    @media #{$small_mobile_one} {
      height: auto;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .col-lg-3 {
      @media #{$laptop_m_four} {
        -ms-flex: 0 0 30%;
        flex: 0 0 30%;
        max-width: 30%;
      }
      @media #{$tab_device} {
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
      }
      @media #{$large_mobile} {
        -ms-flex: 0 0 85%;
        flex: 0 0 85%;
        max-width: 85%;
      }
      @media #{$small_mobile} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .col-lg-9 {
      @media #{$laptop_m_four} {
        -ms-flex: 0 0 70%;
        flex: 0 0 70%;
        max-width: 70%;
      }
      @media #{$tab_device} {
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;
      }
      @media #{$large_mobile} {
        -ms-flex: 0 0 15%;
        flex: 0 0 15%;
        max-width: 15%;
      }
      @media #{$small_mobile} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .logo-box {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      img {
        width: 150px;
        @media #{$small_mobile_three} {
          width: 110px;
        }
      }
      .logo {
        @media #{$tab_device} {
          display: inline-block;
        }
        @media #{$large_mobile} {
          display: inline-block;
        }
        @media #{$small_mobile} {
          display: inline-block;
        }
      }
      .header-category {
        margin-left: auto;
        margin-right: 4px;
        @media #{$small_mobile_one} {
          margin-right: 0;
          margin-left: 40px;
        }
        @media #{$small_mobile_four} {
         margin-left: 30px;
        }
        @media #{$small_mobile_three} {
         margin-left: 15px;
        }
        ul {
          li {
            position: relative;
            a {
              font-size: $default-font-2;
              color: $theme-color;
              text-transform: uppercase;
              font-weight: $font-weight-medium;
              padding-bottom: 41px;
              @include transition(0.3s);
              .fa-th {
                font-size: $default-font-3;
              }
              &:hover {
                color: $theme-color-2
              }
            }
            .dropdown-menu-item {
              position: absolute;
              left: 0;
              top: 58px;
              width: 320px;
              background-color: $white;
              display: block;
              padding: 25px 0 25px 0;
              @include border-radius(4px);
              @include transition(0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275));
              @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
              opacity: 0;
              visibility: hidden;
              @include transform(scale(0.8));
              @media #{$large_mobile} {
                left: -210px;
                overflow-x: hidden;
                height: 392px;
              }
              @media #{$small_mobile} {
                left: -210px;
                overflow-x: hidden;
                height: 392px;
              }
              @media #{$small_mobile_one} {
                left: -150px;
              }
              @media #{$small_mobile_four} {
                left: -165px;
              }
              @media #{$small_mobile_three} {
                left: -130px;
                width: 300px;
              }
              li {
                margin-bottom: 18px;
                &:last-child {
                  margin-bottom: 0;
                }
                .menu-collapse {
                  position: absolute;
                  right: 20px;
                  top: -5px;
                  padding: 10px;
                  background-color: transparent;
                  cursor: pointer;
                  @include transition(0.3s);
                  @include border-radius(30px);
                  &:hover {
                    background-color: rgba(81, 190, 120, 0.1);
                    color: $theme-color-2;
                  }
                }
                a {
                  display: block;
                  padding-right: 30px;
                  padding-left: 30px;
                  padding-bottom: 0;
                }
                .sub-menu {
                  position: absolute;
                  top: -24px;
                  left: 320px;
                  width: 280px;
                  background-color: $white;
                  padding: 25px 0 25px 0;
                  @include border-radius(4px);
                  @include transition(0.3s);
                  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
                  @include transform(scale(0.8));
                  visibility: hidden;
                  opacity: 0;
                  @media #{$large_mobile} {
                    margin-top: 10px;
                    opacity: 1;
                    visibility: visible;
                    left: auto;
                    top: auto;
                    position: inherit;
                    width: 100%;
                    display: none;
                    @include transform(scale(1));
                    @include transition(0s);
                    @include box-shadow(0 0 0 0);
                  }
                  @media #{$small_mobile} {
                    margin-top: 10px;
                    left: auto;
                    top: auto;
                    position: inherit;
                    width: 100%;
                    display: none;
                    @include transform(scale(1));
                    @include transition(0s);
                    @include box-shadow(0 0 0 0);
                  }
                  li {
                    margin-bottom: 18px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                    a {
                      color: $theme-color-4;
                      text-transform: capitalize;
                      position: relative;
                      display: inline-block;
                      padding-right: 0;
                      padding-left: 0;
                      margin-left: 30px;
                      &:after {
                        width: 0;
                        height: 2px;
                        position: absolute;
                        content: '';
                        bottom: -2px;
                        right: 0;
                        background-color: $theme-color-2;
                        @include transition(0.3s);
                      }
                      &:hover {
                        color: $theme-color-2;
                        &:after {
                          width: 100%;
                          right: auto;
                          left: 0;
                        }
                      }
                    }
                  }
                  &.active {
                    display: block;
                  }
                }
                &:hover {
                  .sub-menu {
                    opacity: 1;
                    visibility: visible;
                    @include transform(scale(1));
                  }
                }
                &.active {
                  .menu-collapse {
                    @include transform(rotate(90deg));
                    color: $white;
                    background-color: $theme-color-2;
                  }
                  a {
                    @media #{$large_mobile} {
                      color: $theme-color-2;
                    }
                    @media #{$small_mobile} {
                      color: $theme-color-2;
                    }
                  }
                }
              }
              &:after {
                top: -7px;
                content: "";
                position: absolute;
                background-color: $white;
                left: 30px;
                width: 13px;
                height: 13px;
                @include transform(rotate(45deg));
                @include box-shadow(-2px -2px 10px -5px rgba(0,0,0,.2));
                @media #{$large_mobile} {
                  left: auto;
                  right: 30px;
                }
                @media #{$small_mobile} {
                  left: auto;
                  right: 30px;
                }
              }
            }
            &:hover {
              .dropdown-menu-item {
                opacity: 1;
                visibility: visible;
                @include transform(scale(1));
              }
            }
          }
        }
      }
    }
    .menu-wrapper {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: end;
      justify-content: flex-end;
      .contact-form-action {
        width: 500px;
        margin-right: 35px;
        @media #{$laptop_m_four} {
          width: 100%;
        }
        @media #{$tab_device} {
          width: 380px;
        }
        @media #{$large_mobile} {
          display: none;
        }
        @media #{$small_mobile} {
          display: none;
        }
        form {
          .form-group {
            margin: 0;
            .form-control {
              padding-left: 20px;
            }
          }
        }
      }
      .main-menu {
        margin-right: 35px;
        @media #{$laptop_m_four} {
          display: none;
        }
        ul {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          li {
            margin-right: 34px;
            text-transform: uppercase;
            font-weight: $font-weight-medium;
            position: relative;
            @media #{$laptop_m_four} {
              margin-right: 20px;
            }
            a {
              font-size: $default-font-2;
              color: $theme-color;
              @include transition(0.3s);
              position: relative;
              padding-bottom: 40px;
              &:after {
                width: 0;
                height: 2px;
                position: absolute;
                content: '';
                bottom: 36px;
                right: 0;
                background-color: $theme-color-2;
                @include transition(0.3s);
              }
              &:hover {
                color: $theme-color-2;
                &:after {
                  width: 100%;
                  right: auto;
                  left: 0;
                }
              }
            }
            &:last-child {
              margin-right: 0;
            }
            .dropdown-menu-item {
              position: absolute;
              left: 0;
              top: 58px;
              width: 210px;
              background-color: $white;
              display: block;
              padding: 25px 30px 25px 30px;
              @include border-radius(4px);
              @include transition(0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275));
              @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
              opacity: 0;
              visibility: hidden;
              @include transform(scale(0.8));
              li {
                display: block;
                margin-right: 0;
                margin-bottom: 18px;
                text-transform: capitalize;
                &:last-child {
                  margin-bottom: 0;
                }
                a {
                  color: $theme-color-4;
                  display: inline-block;
                  position: relative;
                  padding-bottom: 0;
                  &:after {
                    bottom: -2px;
                  }
                  &:hover {
                    color: $theme-color-2;
                  }
                }
              }
              &:after {
                top: -7px;
                content: "";
                position: absolute;
                background-color: $white;
                left: 30px;
                width: 13px;
                height: 13px;
                @include transform(rotate(45deg));
                @include box-shadow(-2px -2px 10px -5px rgba(0,0,0,.2));
              }
            }
            &:hover {
              .dropdown-menu-item {
                @include transform(scale(1));
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
      .logo-right-button {
        ul {
          @media #{$laptop_m_four} {
            display: none;
          }
          li {
            .theme-btn {
              background-color: $theme-color-3;
              line-height: 52px;
            }
          }
        }
        .side-menu-open {
          width: 27px;
          cursor: pointer;
          @include border-radius(5px);
          display: none;
          position: relative;
          z-index: 1;
          @media #{$laptop_m_four} {
            display: block;
          }
          @media #{$small_mobile_one} {
           margin-top: -36px;
          }
          @media #{$small_mobile_three} {
            margin-top: -28px;
          }
          .menu__bar {
            height: 2px;
            background-color: $theme-color;
            display: block;
            @include transition(0.3s);
            &:nth-child(1),
            &:nth-child(2) {
              margin-bottom: 5px;
            }
            &:nth-child(2) {
              margin-left: 5px;
            }
          }
          &:hover {
            .menu__bar {
              &:nth-child(2) {
                margin-left: 0;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  .side-nav-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 320px;
    height: 100%;
    background-color: $theme-color;
    overflow-x: hidden;
    z-index: 999;
    @include transition(0.4s cubic-bezier(0.7, 0, 0.2, 1));
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    @include transform(translateX(-100%));
    .humburger-menu {
      .humburger-menu-lines {
        display: inline-block;
        text-align: right;
        width: 22px;
        height: 22px;
        cursor: pointer;
        position: absolute;
        top: 25px;
        right: 18px;
        opacity: .5;
        &:before,
        &:after {
          position: absolute;
          content: '';
          height: 2px;
          width: 100%;
          top: 10px;
          left: 0;
          background-color: $white;
          @include transform(rotate(-43deg));
          @include transition(0.3s);
        }
        &:after {
          @include transform(rotate(43deg));
        }
        &:hover {
          &:before,
          &:after {
            @include transform(rotate(0));
          }
        }
      }
    }
    .side-menu-wrap {
      margin-top: 100px;
      .side-menu-ul {
        .sidenav__item {
          position: relative;
          @include transition(0.3s);
          a {
            color: $white;
            text-transform: uppercase;
            display: block;
            padding: 14px 20px;
            @include transition(0.3s);
            font-weight: $font-weight-medium;
          }
          .menu-plus-icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            line-height: 50px;
            cursor: pointer;
            @include transition(0.3s);
            &:before,
            &:after {
              position: absolute;
              top: 24px;
              right: 20px;
              content: '';
              width: 11px;
              height: 2px;
              background-color: $white;
              @include transform(rotate(-90deg));
              @include transition(0.3s);
            }
            &:after {
              @include transform(rotate(0));
            }
            &:hover {
              background-color: rgba(255, 255, 255, 0.10);
            }
          }
          .side-sub-menu {
            display: none;
            li {
              border-bottom: 1px solid rgba(255, 255, 255, 0.12);
              &:first-child {
                border-top: 1px solid rgba(255, 255, 255, 0.12);
              }
              a {
                padding-left: 30px;
                padding-top: 12px;
                padding-bottom: 12px;
                position: relative;
                text-transform: capitalize;
                &:hover {
                  color: $theme-color-2;
                }
              }
            }
          }
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.12);
          }
          &:hover {
            > a {
              background-color: rgba(255, 255, 255, 0.12);
            }
          }
          &.active {
            .menu-plus-icon {
              &:before {
                @include transform(rotate(0deg));
              }
            }
          }
        }
      }
    }
    .side-btn-box {
      text-align: center;
      margin-top: 40px;
    }
    &.active {
      @include transform(translateX(0));
    }
  }
}
/*-===============================
    header-menu-area2
===============================-*/
.header-menu-area2 {
  padding-right: 0;
  padding-left: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);;
  .header-top {
    border-bottom: transparent;
    background-color: $white;
    .header-widget2 {
      .header-right-info {
        ul {
          &.user-cart {
            li {
              .dropdown-menu-item {
                li {
                  .theme-btn {
                    @include border-radius(30px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .main-menu-content {
    .logo-box {
      .header-category {
        margin-right: 0;
        margin-left: 60px;
        ul {
          li {
            a {
              color: $white;
              text-transform: capitalize;
              .fa-th {
                margin-right: 2px;
              }
            }
            .dropdown-menu-item {
              li {
                a {
                  color: $theme-color;
                  &:hover {
                    color: $theme-color-2;
                  }
                }
              }
            }
          }
        }
      }
    }
    .menu-wrapper {
      .main-menu {
        ul {
          li {
            text-transform: capitalize;
            a {
              color: $white;
            }
          }
        }
      }
      .logo-right-button {
        .social-info {
          li {
            margin-right: 5px;
            &:last-child {
              margin-right: 0;
            }
            a {
              color: $white;
              @include transition(0.3s);
              display: block;
              width: 38px;
              height: 38px;
              line-height: 38px;
              text-align: center;
              @include border-radius(50%);
              background-color: rgba(255, 255, 255, 0.1);
              &:hover {
                color: $theme-color-2;
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }
}
/*---------------- fixed-nav ----------------*/
.fixed-nav .header-top {
  height: 0;
  border: none;
  opacity: 0;
  visibility: hidden;
}
.fixed-nav .header-menu-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  .main-menu-content {
    @include transition(0.5s);
    .logo-box {
      .header-category {
        ul {
          li {
            &:hover {
              .dropdown-menu-item {
                top: 50px;
              }
            }
          }
        }
      }
    }
    .menu-wrapper {
      .main-menu {
        ul {
          li {
            &:hover {
              .dropdown-menu-item {
                top: 50px;
              }
            }
          }
        }
      }
    }
  }
}

/*-===============================
    slider-area
===============================-*/
.slider-area {
  position: relative;
  .single-slide-item {
    position: relative;
    background-position: center center;
    background-size: cover;
    color: $white;
    padding-top: 250px;
    padding-bottom: 330px;
    z-index: 2;
    @media #{$large_mobile} {
      padding-top: 200px;
      padding-bottom: 300px;
    }
    @media #{$large_mobile_four} {
      padding-top: 140px;
      padding-bottom: 250px;
    }
    .slide-item-table {
      display: table;
      width: 100%;
      height: 100%;
      .slide-item-tablecell {
        display: table-cell;
        vertical-align: middle;
        .col-lg-9 {
          @media #{$laptop_m_four} {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
    }
    .slider-heading {
      position: relative;
      z-index: 2;
      .slider__title {
        font-size: $display;
        line-height: 100px;
        font-weight: $font-weight-bold;
        color: $white;
        margin-bottom: 24px;
        @media #{$laptop_m_four} {
          font-size: $display-2;
          line-height: 80px;
        }
        @media #{$tab_device} {
          font-size: $display-3;
          line-height: 75px;
        }
        @media #{$large_mobile} {
          font-size: $display-4;
          line-height: 65px;
        }
        @media #{$device-481} {
          font-size: $primary-font;
          line-height: 50px;
        }
      }
      .slider__text {
        font-size: $display-13;
        line-height: 29px;
        margin-bottom: 40px;
        br {
          @media #{$tab_device} {
            display: none;
          }
          @media #{$large_mobile} {
            display: none;
          }
          @media #{$small_mobile} {
            display: none;
          }
        }
      }
      .slider-promo-btn {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        @media #{$small_mobile_one} {
          display: block;
          text-align: center;
        }
        .video-play-btn {
          margin-left: 20px;
          color: rgba(255, 255, 255, 0.7);
          @include transition(0.3s);
          font-weight: $font-weight-medium;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          @media #{$small_mobile_one} {
            display: block;
            text-align: center;
            margin-left: 0;
            margin-top: 20px;
          }
          span {
            width: 43px;
            height: 43px;
            line-height: 41px;
            text-align: center;
            @include border-radius(50%);
            border: 1px solid rgba(255, 255, 255, 0.7);
            margin-left: 10px;
            font-size: $display-11;
          }
          &:hover {
            color: $white;
            span {
              background-color: $white;
              border-color: $white;
              color: $theme-color-2;
            }
          }
        }
      }
    }
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: $theme-color;
      opacity: .90;
      content: "";
      z-index: -1;
    }
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../images/shape.png");
      background-size: cover;
      background-position: center;
      z-index: 1;
      opacity: 0.1;
    }
  }
  .slide-bg1 {
    background-image: url("../images/slider-img1.jpg");
  }
  .slide-bg2 {
    background-image: url("../images/slider-img2.jpg");
  }
  .slide-bg3 {
    background-image: url("../images/slider-img3.jpg");
    .slider-heading {
      .slider-promo-btn {
        .video-play-btn {
          margin-left: 0;
          margin-right: 20px;
          @media #{$small_mobile_one} {
            margin-right: 0;
            margin-top: 0;
            margin-bottom: 20px;
          }
          span {
            margin-right: 10px;
            margin-left: 0;
          }
        }
      }
    }
  }
  .owl-nav {
    @media #{$large_mobile} {
      display: none;
    }
    @media #{$small_mobile} {
      display: none;
    }
    div {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      @include border-radius(50%);
      background-color: rgba(255, 255, 255, 0.1);
      font-size: $display-13;
      @include transition(0.3s);
      opacity: 0;
      visibility: hidden;
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
      &.owl-prev {
        left: 20px;
      }
      &.owl-next {
        left: auto;
        right: 20px;
      }
    }
  }
  .owl-dots {
    position: absolute;
    bottom: 140px;
    width: 100%;
    text-align: center;
    div {
      width: 14px;
      height: 14px;
      line-height: 14px;
      border: 2px solid $white;
      @include border-radius(50%);
      display: inline-block;
      margin: 0 3px;
      @include transition(0.3s ease-in);
      position: relative;
      &.active,
      &:hover {
        background-color: $theme-color-2;
        border-color: $theme-color-2;
      }
    }
  }
  &:hover {
    .owl-nav {
      div {
        opacity: 1;
        visibility: visible;
        &.owl-prev {
          left: 80px;
          @media #{$laptop_m_two} {
            left: 20px;
          }
        }
        &.owl-next {
          right: 80px;
          @media #{$laptop_m_two} {
            right: 20px;
          }
        }
      }
    }
  }
}
/*-===============================
    slider-area 2
===============================-*/
.slider-area2 {
  .single-slide-item {
    padding-top: 180px;
    padding-bottom: 0;
    @media #{$small_mobile_three} {
      padding-top: 150px;
    }
    .col-lg-6 {
      @media #{$laptop_m_four} {
        -ms-flex: 0 0 63%;
        flex: 0 0 63%;
        max-width: 63%;
      }
      @media #{$tab_device} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      @media #{$large_mobile} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      @media #{$small_mobile} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    #perticles-js-2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .slider-heading {
      @media #{$large_mobile} {
       text-align: center;
      }
      @media #{$small_mobile} {
        text-align: center;
      }
      .slider__title {
        font-size: $display-3;
        line-height: 75px;
        @media #{$large_mobile_three} {
          font-size: $display-4;
          line-height: 65px;
        }
        @media #{$small_mobile_three} {
          font-size: $primary-font;
          line-height: 50px;
        }
      }
      .contact-form-action {
        padding-right: 40px;
        @media #{$large_mobile} {
          padding-right: 0;
        }
        @media #{$small_mobile} {
          padding-right: 0;
        }
        form {
          .form-group {
            .form-control {
              padding-left: 20px;
            }
            .search-icon {
              color: $theme-color;
            }
          }
        }
      }
    }
    &:before {
      display: none;
    }
    &:after {
      opacity: 0.7;
    }
    .how-we-work-content {
      background: #429b85;
      background: -moz-linear-gradient(-45deg,  #429b85 0%, #5f7b8c 100%);
      background: -webkit-linear-gradient(-45deg,  #429b85 0%,#5f7b8c 100%);
      background: linear-gradient(135deg,  #429b85 0%,#5f7b8c 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#429b85', endColorstr='#5f7b8c',GradientType=1 );
      margin-top: 150px;
      padding: 30px 0;
      position: relative;
      overflow: hidden;
      @media #{$tab_device} {
        padding-bottom: 0;
      }
      @media #{$large_mobile} {
        padding-bottom: 0;
      }
      @media #{$small_mobile} {
        padding-bottom: 0;
      }
      .how-we-work-wrap {
        .col-lg-4 {
          @media #{$tab_device} {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
          }
        }
        .how-we-work-item {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          @media #{$laptop_m_four} {
            display: block;
            text-align: center;
          }
          @media #{$tab_device} {
            margin-bottom: 30px;
          }
          @media #{$large_mobile} {
            margin-bottom: 30px;
          }
          @media #{$small_mobile} {
            margin-bottom: 30px;
          }
          .la {
            font-size: $display-7;
            width: 55px;
            height: 55px;
            line-height: 55px;
            @include border-radius(50%);
            text-align: center;
            background-color: rgba(255, 255, 255, 0.1);
            @include transition(0.3s);
          }
          .how-work__text {
            margin-left: 14px;
            @media #{$laptop_m_four} {
              margin-left: 0;
              margin-top: 20px;
            }
            .hww__sub-title {
              font-size: $display-11;
              color: $white;
              font-weight: $font-weight-semi-bold;
              margin-bottom: 2px;
            }
          }
          &:hover {
            .la {
              background-color: $white;
              color: $theme-color-2;
            }
          }
        }
      }
      .hw-circle {
        width: 50px;
        height: 50px;
        @include border-radius(50%);
        position: absolute;
        border: 8px solid $white;
        opacity: 0.1;
        &:nth-child(1) {
          top: -20px;
          left: 30px;
        }
        &:nth-child(2) {
          top: 73%;
          left: 50%;
        }
        &:nth-child(3) {
          top: 5px;
          right: 10%;
        }
      }
    }
  }
  .slide-bg1 {
    background-image: url("../images/slider-img4.jpg");
  }
}
/*====================================================
    about-breadcrumb
 ====================================================*/
.about-breadcrumb {
  background-color: $theme-color-5;
  position: relative;
  padding-top: 167px;
  padding-bottom: 167px;
  z-index: 2;
  color: $white;
  background-image: url("../images/about-breadcrumb.jpg");
  background-size: cover;
  background-position: center;
  @media #{$large_mobile} {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  @media #{$small_mobile} {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .breadcrumb-content {
    .breadcrumb__meta {
      display: inline-block;
      text-transform: uppercase;
      font-size: $default-font-3;
      color: $white;
      background-color: rgba(255, 255, 255, 0.1);
      padding: 5px 10px;
      @include border-radius(4px);
      letter-spacing: 0.6px;
      margin-bottom: 5px;
    }
    .breadcrumb__title {
      color: $white;
      font-size: $display-4;
      line-height: 75px;
      font-weight: $font-weight-semi-bold;
      @media #{$large_mobile} {
        font-size: $display-5;
        line-height: 65px;
      }
      @media #{$small_mobile} {
        font-size: $display-5;
        line-height: 60px;
      }
      br {
        @media #{$small_mobile_one} {
          display: none;
        }
      }
    }
    .breadcrumb__btn {
      margin-top: 48px;
      display: inline-block;
      .video-play-btn {
        color: $white;
        font-size: $display-12;
        font-weight: $font-weight-medium;
        @include transition(0.3s);
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        &:hover {
          color: $theme-color-2;
          span {
            color: $theme-color-2;
          }
        }
        span {
          position: relative;
          display: inline-block;
          width: 70px;
          height: 70px;
          font-size: $display-8;
          text-align: center;
          line-height: 70px;
          color: $theme-color;
          margin-right: 20px;
          @include border-radius(50%);
          background-color: $white;
          z-index: 1;
          @include transition(0.3s);
         &:before,
         &:after {
           position: absolute;
           content: '';
           top: -20px;
           left: -20px;
           bottom: -20px;
           right: -20px;
           background-color: rgba(255,255,255, 0.2);
           @include border-radius(50%);
           -webkit-animation: play-button-pulse 1.8s linear infinite;
           -moz-animation: play-button-pulse 1.8s linear infinite;
           animation: play-button-pulse 1.8s linear infinite;
           opacity: 0;
           z-index: -1;
         }
          &:after {
            -webkit-animation-delay: .6s;
            -moz-animation-delay: .6s;
            animation-delay: .6s;
          }
        }
      }
    }
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $theme-color;
    opacity: 0.8;
    z-index: -1;
  }
}
/*============== play-button-pulse ==============*/
@-webkit-keyframes play-button-pulse {
  0% {
   @include transform(scale(0.5));
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    @include transform(scale(1.2));
    opacity: 0;
  }
}
@-moz-keyframes play-button-pulse {
  0% {
    @include transform(scale(0.5));
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    @include transform(scale(1.2));
    opacity: 0;
  }
}
@-ms-keyframes play-button-pulse {
  0% {
    @include transform(scale(0.5));
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    @include transform(scale(1.2));
    opacity: 0;
  }
}
@-o-keyframes play-button-pulse {
  0% {
    @include transform(scale(0.5));
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    @include transform(scale(1.2));
    opacity: 0;
  }
}
@keyframes play-button-pulse {
  0% {
    @include transform(scale(0.5));
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    @include transform(scale(1.2));
    opacity: 0;
  }
}

/*====================================================
    breadcrumb-area
 ====================================================*/
.breadcrumb-area {
  background-color: $theme-color-5;
  height: 450px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  text-align: center;
  z-index: 2;
  color: $white;
  background-image: url("../images/breadcrumb-bg.jpg");
  background-size: cover;
  background-position: center;
  @media #{$tab_device} {
    height: 300px;
  }
  @media #{$large_mobile} {
    height: 300px;
  }
  @media #{$small_mobile} {
    height: 300px;
  }
  @media #{$small_mobile_four} {
    height: 220px;
  }
  .breadcrumb-content {
    position: relative;
    z-index: 3;
    .breadcrumb__title {
      font-size: $display-3;
      text-transform: capitalize;
      font-weight: $font-weight-bold;
      color: $white;
      @media #{$tab_device} {
        font-size: $display-5;
      }
      @media #{$large_mobile} {
        font-size: $display-5;
      }
      @media #{$small_mobile} {
        font-size: $display-6;
      }
    }
    .breadcrumb__list {
      margin-top: 8px;
      li {
        display: inline-block;
        text-transform: capitalize;
        color: $theme-color-5;
        font-size: $default-font;
        font-weight: $font-weight-medium;
        position: relative;
        padding-right: 22px;
        &:last-child {
          padding-right: 0;
        }
        a {
          color: $theme-color-5;
          @include transition(0.3s);
          &:hover {
            color: $theme-color-2;
          }
        }
        &.active__list-item {
          &:after {
            position: absolute;
            content: '.';
            top: -6px;
            right: 0;
            padding: 0 6px;
            font-size: $display-12;
          }
        }
      }
    }
    .text-outline {
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: rgba(255, 255, 255, 0.1);
      -ms-text-stroke-color: rgba(255, 255, 255, 0.1);
      -o-text-stroke-color: rgba(255, 255, 255, 0.1);
      font-size: 140px;
      color: transparent;
      position: absolute;
      letter-spacing: -0.02em;
      bottom: -115px;
      left: 0;
      right: 0;
      z-index: -1;
      text-transform: capitalize;
      font-weight: $font-weight-semi-bold;
      @media #{$laptop_m_four} {
        display: none;
      }
    }
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $theme-color;
    opacity: 0.9;
    z-index: -1;
  }
}
/*====================================================
    breadcrumb-area 2
 ====================================================*/
.breadcrumb-area2 {
  text-align: left;
  @media #{$tab_device} {
    height: 450px;
  }
  @media #{$large_mobile} {
    height: 450px;
  }
  @media #{$small_mobile} {
    height: 450px;
  }
  .breadcrumb-content {
    padding-right: 400px;
    @media #{$tab_device} {
      padding-right: 0;
    }
    @media #{$large_mobile} {
      padding-right: 0;
    }
    @media #{$small_mobile} {
      padding-right: 0;
    }
    .breadcrumb__meta {
      margin-bottom: 5px;
      .seller-badge {
        background-color: $theme-color-2;
        color: $white;
        text-transform: uppercase;
        font-weight: $font-weight-semi-bold;
        display: inline-block;
        padding: 4px 10px 4px 12px;
        @include border-radius(4px 4px 0 4px);
        font-size: $default-font-5;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          top: 0;
          left: 6px;
          z-index: -1;
          background-color: $theme-color-2;
          -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
          @include transform(skew(12deg, 0deg));
          @include border-radius(4px 4px 0 4px);
        }
      }
    }
    .breadcrumb__title {
      font-size: $display-5;
      text-transform: inherit;
      line-height: 58px;
      @media #{$large_mobile_three} {
        font-size: $display-6;
        line-height: 50px;
      }
    }
    .breadcrumb__list {
      margin-top: 20px;
      li {
        text-transform: inherit;
        padding-right: 10px;
        padding-bottom: 5px;
        &:last-child {
          padding-bottom: 0;
        }
        i.la {
          color: $alter-color-2;
        }
        i.la-globe {
          color: $white;
        }
      }
    }
  }
}
/*====================================================
    instructor-breadcrumb
 ====================================================*/
.instructor-breadcrumb {
  text-align: left;
  .breadcrumb-content {
    .breadcrumb__title {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-flex-align: center;
       align-items: center;
      .la {
        font-size: $display-8;
        color: $theme-color-2;
        margin-left: 10px;
      }
    }
    .breadcrumb__list {
      margin-top: 15px;
      li {
        font-size: $display-12;
      }
    }
  }
}

/*====================================================
    ERROR AREA
 ====================================================*/
.error-area {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-bottom: 120px;
  }
  @media #{$small_mobile} {
    padding-bottom: 120px;
  }
  .error-content {
    img {
      width: 100%;
    }
    .error__title {
      font-size: $primary-font;
      font-weight: $font-weight-semi-bold;
      margin-top: 33px;
      margin-bottom: 20px;
    }
    .error__text {
      font-size: $display-13;
      line-height: 28px;
      margin-bottom: 35px;
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
    top: 55px;
    left: 55px;
    width: 200px;
    height: 200px;
    background-image: url(../images/dots.png);
    background-position: center;
    background-size: cover;
    z-index: -1;
    @include border-radius(50%);
    opacity: 0.3;
  }
  &:after {
    bottom: 55px;
    right: 55px;
    left: auto;
    top: auto;
  }
}

/*====================================================
    COURSE DETAIL AREA
 ====================================================*/
.course-detail {
  @media #{$large_mobile} {
    padding-bottom: 120px;
  }
  @media #{$small_mobile} {
    padding-bottom: 120px;
  }
  .course-item-wrap {
    .course-detail__title {
      font-weight: $font-weight-semi-bold;
      font-size: $display-9;
      margin-bottom: 25px;
    }
  }
  .what-you-get {
    background-color: $theme-color-5;
    border: 1px solid rgba(127, 136, 151, 0.1);
    padding: 25px 30px 15px 30px;
    @include border-radius(4px);
    margin-top: 100px;
    margin-bottom: 53px;
    .what-you-get__items {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      @media #{$large_mobile} {
        display: block !important;
      }
      @media #{$small_mobile} {
        display: block !important;
      }
      .what-you-get__item {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 15px;
        width: 45%;
        @media #{$large_mobile} {
          display: block !important;
          width: 100%;
        }
        @media #{$small_mobile} {
          display: block !important;
          width: 100%;
        }
        .what-you-get__icon {
          align-self: flex-start;
          margin-top: 3px;
          margin-right: 10px;
          color: $alter-color-2;
          font-size: $display-13;
        }
      }
    }
  }
  .requirement-wrap {
    margin-bottom: 51px;
    .requirements__list {
      .requirements__item {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        .requirements__icon {
          color: $theme-color;
          margin-right: 2px;
        }
      }
    }
  }
  .description-wrap {
    margin-bottom: 46px;
    .description__text {
      line-height: 28px;
      margin-bottom: 15px;
    }
  }
  .audience-wrap {
    .requirements__list {
      .requirements__item {
        .requirements__icon {
         font-size: $default-font-3;
        }
      }
    }
  }
  .curriculum-wrap {
    margin-bottom: 50px;
    .curriculum-header {
      margin-bottom: 30px;
      @media #{$large_mobile} {
        display: block !important;
      }
      @media #{$small_mobile} {
        display: block !important;
      }
      .course-detail__title {
        margin-bottom: 0;
      }
      .curriculum-header-right {
        @media #{$large_mobile} {
         margin-top: 8px;
        }
        @media #{$small_mobile} {
         margin-top: 8px;
        }
        span {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
          strong {
            color: $theme-color;
          }
        }
      }
    }
    .curriculum-content {
      .course-accordion {
        .card {
          margin-bottom: 4px;
          border-color: rgba(127, 136, 151, 0.3);
          @include border-radius(2px);
          &:first-of-type {
            border-bottom: 1px solid rgba(127, 136, 151, 0.3);
            @include border-radius(2px);
          }
          &:not(:first-of-type):not(:last-of-type) {
            border-bottom: 1px solid rgba(127, 136, 151, 0.3);
          }
          .card-header {
            padding: 0;
            text-align: left;
            background-color: $theme-color-5;
            border-bottom-color: rgba(127, 136, 151, 0.3);
            .btn-link {
              display: block;
              width: 100%;
              text-align: left;
              @include border-radius(0);
              padding: 10px 20px 10px 50px;
              border: none;
              color: $theme-color;
              text-decoration: none;
              font-size: $display-13;
              position: relative;
              font-weight: $font-weight-medium;
              i.la {
                display: none;
                position: absolute;
                left: 20px;
                top: 50%;
                @include transform(translateY(-50%));
                color: $theme-color-4;
              }
              .btn-text {
                float: right;
                color: $theme-color-4;
                font-size: $default-font;
              }
            }
            .btn-link[aria-expanded=true] i.la.la-angle-up {
              display: block;
            }
            .btn-link[aria-expanded=false] i.la.la-angle-down {
              display: block;
            }
          }
          .card-body {
            padding: 0;
            .card-list {
              .card-list-item {
                position: relative;
                border-bottom: 1px solid rgba(127, 136, 151, 0.2);
                padding: 10px 20px 10px 50px;
                @media #{$small_mobile_four} {
                 padding-left: 20px;
                }
                &:last-child {
                  border-bottom: 0;
                }
                .course-play__icon {
                  margin-right: 5px;
                }
                .course-duration {
                  position: absolute;
                  right: 20px;
                  top: 50%;
                  @include transform(translateY(-50%));
                  color: $theme-color-2;
                }
                .preview-link {
                  background-color: transparent;
                  outline: none;
                  border: none;
                  color: $theme-color-2;
                  @include transition(0.3s);
                  &:hover {
                    color: $theme-color;
                  }
                }
                .modal {
                  .modal-title {
                    line-height: 26px;
                  }
                  .modal-dialog {
                    max-width: 650px;
                    @media #{$large_mobile} {
                      max-width: 500px;
                    }
                    @media #{$small_mobile} {
                      max-width: 500px;
                    }
                    @media #{$large_mobile_three} {
                      max-width: 100%;
                    }
                  }
                }
                .cart-disable__text {
                  .cart-question__text {
                    float: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .view-more-courses {
    margin-bottom: 56px;
    .owl-dots {
      text-align: center;
      div {
        width: 14px;
        height: 14px;
        line-height: 14px;
        background-color: rgba(127, 136, 151, 0.4);
        @include border-radius(50%);
        display: inline-block;
        margin: 0 2px;
        position: relative;
        @include transition(0.3s);
        @include transform(scale(0.7));
        &.active,
        &:hover {
          background-color: $theme-color-2;
          @include transform(scale(1));
        }
      }
    }
  }
  .instructor-wrap {
    padding-top: 56px;
    border-top: 1px solid rgba(127, 136, 151, 0.2);
    margin-bottom: 60px;
    .instructor-content {
      .instructor-img {
        margin-right: 40px;
        width: 270px;
        @media #{$large_mobile} {
          width: 100%;
          margin-right: 0;
          margin-bottom: 40px;
        }
        @media #{$small_mobile} {
          width: 100%;
          margin-right: 0;
          margin-bottom: 40px;
        }
        .instructor__avatar {
          margin-bottom: 15px;
          display: inline-block;
          img {
            @include border-radius(50%);
            width: 108px;
            height: 108px;
          }
        }
        .instructor__stats {
          li {
            display: block;
            margin-bottom: 6px;
            .la {
              font-size: $display-13;
              margin-right: 3px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            a {
              color: $theme-color;
              @include transition(0.3s);
              &:hover {
                color: $theme-color-2;
              }
            }
          }
        }
      }
      .instructor-details {
        width: 100%;
        .instructor-titles {
          .instructor__title {
            font-size: $display-10;
            font-weight: $font-weight-semi-bold;
            a {
              color: $theme-color;
              @include transition(0.3s);
              &:hover {
                color: $theme-color-2;
              }
            }
            .Instructor__title-icon {
              color: $theme-color-2;
              font-size: $display-11;
            }
          }
          .instructor__subtitle {
            font-weight: $font-weight-medium;
            margin-bottom: 5px;
          }
          .instructor__meta {
            color: $theme-color;
          }
        }
        .instructor-desc {
          margin-top: 10px;
          .instructor__text {
            line-height: 28px;
          }
        }
      }
    }
    .instructor-content.d-flex {
      @media #{$large_mobile} {
        display: block !important;
      }
      @media #{$small_mobile} {
        display: block !important;
      }
    }
  }
  .review-wrap {
    .review-content {
      margin-top: 40px;
      .review-rating-summary {
        .review-rating-summary-inner {
          .stats-average__count {
            .stats-average__count-count {
              color: $theme-color;
              font-size: $display-3;
              font-weight: $font-weight-semi-bold;
            }
          }
          .stats-average__rating {
            margin-left: 10px;
            ul {
              margin-right: 5px;
              li {
                .la {
                  color: $alter-color-2;
                  font-size: $display-12;
                }
              }
            }
          }
        }
        .course-rating-text {
          text-align: center;
          margin-top: 5px;
          @media #{$laptop_m_four} {
            text-align: left;
            margin-bottom: 30px;
          }
          .course-rating-text__text {
            margin-left: 60px;
            @media #{$laptop_m_four} {
             margin-left: 0;
            }
          }
        }
      }
      .review-rating-widget {
        margin-left: 50px;
        width: 100%;
        @media #{$laptop_m_four} {
          margin-left: 0;
        }
        .review-rating-rate {
          ul {
            .review-rating-rate__items {
              margin-bottom: 10px;
              @media #{$small_mobile_four} {
               margin-bottom: 15px;
              }
              &:last-child {
                margin-bottom: 0;
              }
              .review-rating-inner__item {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                @media #{$small_mobile_four} {
                  display: block !important;
                }
                .review-rating-rate__item-text {
                  text-transform: uppercase;
                  font-weight: $font-weight-medium;
                  font-size: $default-font-2;
                  width: 75px;
                  @media #{$small_mobile_four} {
                    width: 100%;
                    margin-bottom: 5px;
                  }
                }
                .review-rating-rate__item-fill {
                  width: 310px;
                  height: 20px;
                  background-color: rgba(127, 136, 151, 0.1);
                  @include border-radius(4px);
                  margin-right: 15px;
                  @media #{$large_mobile_four} {
                    width: 290px;
                  }
                  @media #{$small_mobile_one} {
                    width: 260px;
                  }
                  @media #{$small_mobile_four} {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 5px;
                  }
                  .review-rating-rate__item-fill__fill {
                    background-color: $alter-color-2;
                    @include border-radius(4px);
                    display: block;
                    width: 77%;
                    height: 100%;
                  }
                  .rating-fill-width2 {
                    width: 54%;
                  }
                  .rating-fill-width3 {
                    width: 14%;
                  }
                  .rating-fill-width4 {
                    width: 5%;
                  }
                  .rating-fill-width5 {
                    width: 2%;
                  }
                }
                .review-rating-rate__item-percent-text {
                  opacity: 0.8;
                }
              }
            }
          }
        }
      }
    }
    .review-content.d-flex {
      @media #{$laptop_m_four} {
        display: block !important;
      }
    }
    .comments-wrapper {
      margin-top: 52px;
    }
  }
  .view-more-courses2 {
    margin-top: 60px;
    .view-more-carousel {
      padding-top: 30px;
      border-top: 1px solid rgba(127, 136, 151, 0.2);
    }
    .more-course-btn {
      margin-top: 50px;
      .report-modal {
        margin-top: -6px;
        .report-btn {
          border: 1px solid rgba(127, 136, 151, 0.2);
          outline: none;
          background-color: $white;
          color: $theme-color;
          font-weight: $font-weight-medium;
          @include transition(0.3s);
          line-height: 40px;
          padding-right: 20px;
          padding-left: 20px;
          text-transform: capitalize;
          &:hover {
            background-color: rgba(127, 136, 151, 0.1);;
            border-color: rgba(127, 136, 151, 0.4);
          }
          .report-btn__icon {
            margin-right: 3px;
          }
        }
        .modal {
          .modal-content {
            .modal-header {
              .modal-title {
                font-weight: $font-weight-semi-bold;
              }
            }
            .modal-body {
              .contact-form-action {
                form {
                  .form-group {
                    .label-text {
                      color: $theme-color;
                    }
                    .form-control {
                      padding-left: 20px;
                    }
                    .theme-btn {
                      padding-right: 20px;
                      padding-left: 20px;
                      line-height: 45px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .sidebar-component {
    position: relative;
    z-index: 3;
    margin-top: -90px;
    @media #{$tab_device} {
     margin-top: 0;
    }
    @media #{$large_mobile} {
     margin-top: 0;
    }
    @media #{$small_mobile} {
     margin-top: 0;
    }
    .sidebar {
      .sidebar-preview {
        padding: 0;
        border: none;
        background-color: $white;
        .sidebar-preview-titles {
          background-color: $theme-color-2;
          padding: 20px 30px;
          @include border-radius(4px 4px 0 0);
          .widget__title {
            color: $white;
            margin-bottom: 0;
          }
          .section__divider {
            background-color: $white;
            margin: 0;
            &:after {
              background-color: $theme-color-2;
            }
          }
        }
        .preview-video-and-details {
          border: 1px solid rgba(127, 136, 151, 0.2);
          .preview-course-video {
            padding: 4px;
            iframe {
              @include border-radius(4px);
            }
          }
          .preview-course-content {
            padding: 34px 30px 30px 30px;
            border-top: 0;
            margin-top: -5px;
            .preview-course__price {
              margin-bottom: 16px;
              span {
                margin-right: 7px;
                font-size: $display-13;
                &.price-current {
                  color: $theme-color;
                  font-size: $display-6;
                  font-weight: $font-weight-semi-bold;
                  margin-right: 10px;
                  @media #{$laptop_m_four} {
                    margin-right: 0;
                    margin-bottom: 10px;
                  }
                }
                &.price-before {
                  text-decoration: line-through;
                }
                &.price-discount {
                  margin-right: 0;
                }
              }
            }
            .preview-course__price.d-flex {
              @media #{$laptop_m_four} {
                display: block !important;
              }
            }
            .preview-price-discount__text {
              margin-bottom: 30px;
              .discount-left__text-text {
                color: $alter-color-2;
                font-weight: $font-weight-medium;
              }
            }
            .buy-course-btn {
              margin-bottom: 15px;
              .theme-btn {
                width: 100%;
                display: block;
              }
            }
            .preview-course-incentives {
              .preview-course-incentives__text {
                text-align: center;
              }
              .preview-course-incentives__title {
                color: $theme-color;
                font-size: $display-12;
                font-weight: $font-weight-medium;
                margin-top: 20px;
                margin-bottom: 20px;
              }
              .preview-course-incentives__list {
                li {
                  display: block;
                  margin-bottom: 15px;
                  position: relative;
                  padding-left: 27px;
                  span {
                    position: absolute;
                    top: 1px;
                    left: 0;
                    font-size: $display-12;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
      .sidebar-feature {
        .widget__list {
          li {
            padding-left: 0;
            border-bottom: 1px solid rgba(127, 136, 151, 0.1);
            padding-bottom: 15px;
            .course-feature__icon {
              margin-right: 5px;
            }
            .course-feature__meta {
              float: right;
            }
            &:after {
              display: none;
            }
            &:last-child {
              padding-bottom: 0;
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

/*=============== cart-area ===============*/
.cart-area {
  padding-top: 120px;
  padding-bottom: 80px;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  .table {
    thead>tr>td,
    thead>tr>th {
      border-bottom-width: 1px;
    }
    img {
      width: 100px;
    }
    tr  {
      td {
        padding: 15px 20px;
        vertical-align: middle;
        line-height: inherit;
        position: relative;
        &.cart__title {
          font-size: $default-font;
          text-transform: capitalize;
          color: $theme-color;
          font-weight: $font-weight-semi-bold;
        }
        .cartproduct__desc {
          img {
            margin-right: 20px;
            border: 1px solid $color;
            @include border-radius(2px);
          }
          a {
            font-size: $default-font;
            color: $theme-color;
            font-weight: $font-weight-bold;
            @include transition(0.3s);
            display: inline-block;
            margin-bottom: 7px;
            &:hover {
              color: $theme-color-2;
            }
          }
          p {
            font-size: $default-font-2;
            color: $theme-color-4;
            font-weight: $font-weight-medium;
            width: calc(100% - 80px);
          }
        }
        .item__price {
          color: $theme-color;
          font-weight: $font-weight-semi-bold;
          .item__before-price {
            color: $theme-color-4;
            text-decoration: line-through;
          }
        }
        .input-number-group {
          .input-number-decrement {
            margin-left: 0;
          }
        }
        .button-remove {
          background-color: transparent;
          border: 1px solid $color;
          width: 35px;
          height: 35px;
          text-align: center;
          line-height: 0;
          @include border-radius(50%);
          @include transition(0.3s);
          color: $theme-color-4;
          position: absolute;
          left: 50%;
          top: 50%;
          @include transform(translate(-50%, -50%));
          .fa-close {
            margin-top: -1px;
          }
          &:hover {
            background-color: $theme-color-2;
            color: $white;
            border-color: $theme-color-2;
          }
        }
      }
    }
  }
  .cart-detail-wrap {
    margin-top: 20px;
    .cart-detail-item {
      background-color: $theme-color-5;
      @include border-radius(4px);
      padding: 30px 30px 33px 30px;
      margin-bottom: 30px;
      .cartdetail__title {
        font-size: $display-13;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 21px;
      }
      .cartdetail__content {
        .cart__detail-desc {
          line-height: 28px;
          margin-bottom: 26px;
        }
        .cart-table {
          margin-top: -5px;
          tr {
            th {
              border-bottom: none;
              color: $theme-color;
              line-height: inherit;
              padding: 15px 0;
              font-weight: $font-weight-semi-bold;
            }
            td {
              color: $theme-color-4;
              font-weight: $font-weight-medium;
              text-align: right;
            }
          }
        }
      }
    }
  }
  .view-more-courses {
    margin-top: 40px;
    .view-more-courses__title {
      font-weight: $font-weight-semi-bold;
      font-size: $display-10;
      margin-bottom: 35px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(127, 136, 151, 0.2);
    }
  }
}
/*=============== checkout-area ===============*/
.checkout-area {
  padding-top: 115px;
  padding-bottom: 80px;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-bottom: 50px;
  }
  @media #{$small_mobile} {
    padding-bottom: 50px;
  }
  .billing-content {
    background-color: $theme-color-5;
    @include border-radius(4px);
    padding: 20px;
    margin-bottom: 30px;
    .checkout-table {
      thead {
        th {
          padding-top: 0;
          border-top: none;
          border-bottom: none;
        }
      }
      th {
        color: $theme-color;
      }
      td {
        color: $theme-color-4;
      }
      th,
      td{
        font-weight: $font-weight-semi-bold;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  .checkout-billing-secured {
    .term-text a {
      color: $theme-color-2;
    }
    .billing__secure-connection {
      margin-top: 15px;
      display: -ms-flex;
      display: -moz-flex;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      i {
        font-size: $display-7;
        margin-right: 10px;
      }
    }
  }
}
.checkout-header .checkout__title {
  font-size:$display-10;
  font-weight: $font-weight-semi-bold;
  margin-bottom: 25px;
}

.payment-method-wrap {
  margin-top: 20px;
  padding-top: 25px;
  border-top: 1px solid rgba(127, 136, 151, 0.2);
  .payment-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: $default-font;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: $font-weight-medium;
    color: $theme-color-4;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        border-color: $theme-color-2;
      }
      &:checked ~ .checkmark:after {
        @include transform(scale(1));
        opacity: 1;
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      margin: 3px 0 0 0;
      border: 2px solid $color;
      @include border-radius(50%);
      @include transition(0.3s);
      &:after {
        top: 2px;
        left: 2px;
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        background-color: $theme-color-2;
        opacity: 0;
        @include transform(scale(0.1));
        @include border-radius(50%);
        @include transition(0.3s);
      }
    }
    &:hover input ~ .checkmark:after {
      @include transform(scale(1));
      opacity: 1;
    }
    &:hover input ~ .checkmark {
      border-color: $theme-color-2;
    }
  }
  .theme-button {
    border: none;
    margin-top: 25px;
    margin-bottom: 5px;
    padding: 12px 25px;
  }
}














/*=============== comments-wrapper ===============*/
.comments-wrapper {
  .comments-title,
  .contact-title {
    font-size: $display-10;
    font-weight: $font-weight-semi-bold;
  }
  .comments-list {
    margin-top: 20px;
    padding-top: 40px;
    margin-bottom: 45px;
    border-top: 1px solid rgba(127, 136, 151, 0.2);
    li {
      .comment {
        border-bottom: 1px solid $color;
        margin-bottom: 50px;
        padding-bottom: 50px;
        @media #{$large_mobile} {
         text-align: center;
        }
        @media #{$small_mobile} {
         text-align: center;
        }
        .avatar__img {
          width: 100px;
          height: 100px;
          @include border-radius(50%);
          margin-right: 20px;
          float: left;
          @media #{$large_mobile} {
           float: none;
            margin-right: 0;
          }
          @media #{$small_mobile} {
           float: none;
            margin-right: 0;
          }
        }
        .comment-body {
          margin-left: 120px;
          @media #{$large_mobile} {
           margin-left: 0;
          }
          @media #{$small_mobile} {
           margin-left: 0;
          }
          .meta-data {
            position: relative;
            @media #{$large_mobile} {
              margin-top: 15px;
            }
            @media #{$small_mobile} {
              margin-top: 15px;
            }
            .comment__author {
              font-size: $display-11;
              text-transform: capitalize;
              color: $theme-color;
              margin-bottom: 5px;
              display: inline-block;
              font-weight: $font-weight-semi-bold;
            }
            .comment__date {
              font-size: $default-font-2;
              color: $theme-color-4;
            }
            .review-stars {
              position: absolute;
              right: 10px;
              top: 10px;
              @media #{$large_mobile} {
                position: inherit;
                right: auto;
                top: auto;
                margin-top: 4px;
              }
              @media #{$small_mobile} {
                position: inherit;
                right: auto;
                top: auto;
                margin-top: 4px;
              }
              li {
                display: inline-block;
                color: $alter-color-2;
                &:nth-child(4),
                &:nth-child(5) {
                  color: $theme-color-4;
                }
              }
            }
            .review-stars2 {
              li {
                &:nth-child(4) {
                  color: $alter-color-2;
                }
              }
            }
            .review-stars3 {
              li {
                &:nth-child(4),
                &:nth-child(5) {
                  color: $alter-color-2;
                }
              }
            }
          }
          .comment-content {
            font-size: $default-font;
            color: $theme-color-4;
            margin-top: 20px;
            margin-bottom: 15px;
            line-height: 28px;
            @media #{$large_mobile_two} {
              margin-bottom: 20px;
            }
          }
          .comment-reply {
            .comment__btn {
              color: $theme-color-4;
              position: relative;
              @include transition(0.3s);
              font-size: $default-font;
              display: inline-block;
              padding: 7px 18px;
              border: 1px solid $color;
              @include border-radius(30px);
              font-weight: $font-weight-medium;
              &:hover {
                background-color: $theme-color-2;
                color: $white;
                border-color: $theme-color-2;
              }
            }
            .helpful__action {
              float: right;
              margin-top: 10px;
              @media #{$large_mobile} {
                margin-top: 30px;
                float: none;
              }
              @media #{$small_mobile} {
                margin-top: 30px;
                float: none;
              }
              a {
                border: 1px solid rgba(128, 137, 150, 0.4);
                @include border-radius(4px);
                padding: 4px 12px;
                color: $theme-color-4;
                margin-left: 3px;
                margin-right: 3px;
                @include transition(0.3s);
                &:hover {
                  background-color: rgba(128, 137, 150, 0.1);
                  color: $theme-color;
                }
              }
            }
          }
        }
      }
      .comments-reply {
        margin-left: 130px;
        @media #{$large_mobile} {
         margin-left: 0;
        }
        @media #{$small_mobile} {
         margin-left: 0;
        }
      }
    }
  }
  .see-more-review-btn {
    margin-bottom: 50px;
    .button-shared {
      .theme-btn {
        line-height: 45px;
        padding-right: 20px;
        padding-left: 20px;
        font-size: $default-font-2;
        outline: none;
      }
    }
  }
}

/*======================================
      faq-area
 ======================================*/
.faq-area {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
  .col-lg-8 {
    @media #{$tab_device} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    @media #{$large_mobile} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    @media #{$small_mobile} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .sidebar  {
    @media #{$tab_device} {
      margin-top: 56px;
    }
    @media #{$large_mobile} {
      margin-top: 56px;
    }
    @media #{$small_mobile} {
      margin-top: 56px;
    }
  }
}
.faq-body {
  margin-top: -20px;
  .faq-panel {
    .faq-heading {
      margin-top: 20px;
      .faq__title {
        color: $theme-color;
        width: 100%;
        cursor: pointer;
        padding: 20px 30px 18px 30px;
        display: block;
        border: 1px solid rgba(127, 136, 151, 0.2);
        font-weight: $font-weight-medium;
        font-size: $display-12;
        background-color: $white;
        position: relative;
        @include border-radius(4px);
        @include transition(0.3s);
        @media #{$small_mobile_four} {
          font-size: $display-4;
        }
        @media #{$small_mobile_two} {
          font-size: $default-font;
        }
        @media #{$small_mobile_two} {
          padding-right: 20px;
          padding-left: 20px;
        }
        i {
          float: right;
          @include transition(0.3s);
        }
        &:hover {
          color: $theme-color-2;
        }
      }
    }
    .faq-content {
      padding: 23px 30px 21px 30px;
      display: none;
      @include box-shadow(1px 7px 41px -8px rgba(82, 85, 90, 0.1));
      @include border-radius(0 0 5px 5px);
      border: 1px solid rgba(127, 136, 151, 0.2);
      border-top: 0;
      @media #{$small_mobile_two} {
        padding-right: 20px;
        padding-left: 20px;
      }
      .faq__desc {
        font-size: $default-font;
        color: $theme-color-4;
        line-height: 28px;
      }
    }
    &.active {
      .faq-heading {
        .faq__title {
          color: $theme-color-2;
          @include border-radius(4px 4px 0 0);
          i {
            @include transform(rotate(-90deg));
          }
          &:after {
            z-index: -1;
          }
        }
      }
    }
  }
  .faq-btn-box {
    margin-top: 60px;
  }
}
/*====================================================
    ADMISSION
 ====================================================*/
.admission-area {
  padding-top: 120px;
  padding-bottom: 90px;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-bottom: 120px;
  }
  @media #{$small_mobile} {
    padding-bottom: 120px;
  }
  .admission-body {
    margin-top: 40px;
    .contact-form-action {
      form {
        .form-group {
          .form-control {
            padding-left: 20px;
          }
        }
      }
    }
  }
}
/*====================================================
    GALLERY
 ====================================================*/
.gallery-area {
  padding-top: 112px;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-bottom: 90px;
  }
  @media #{$small_mobile} {
    padding-bottom: 90px;
  }
  .portfolio-filter {
    text-align: center;
    li {
      display: inline-block;
      padding: 8px 25px;
      cursor: pointer;
      color: $theme-color;
      @include border-radius(4px);
      position: relative;
      z-index: 1;
      @include transition(0.4s);
      font-size: $default-font;
      font-weight: $font-weight-medium;
      &:hover {
        color: $theme-color-2;
        background-color: rgba(81, 190, 120, 0.1);
      }
      &.active {
        color: $white;
        background-color: $theme-color-2;
        @include box-shadow(0 10px 30px rgba(81, 190, 120, 0.2));
      }
    }
  }
  .portfolio-wrap {
    padding-right: 0;
    padding-left: 0;
    .portfolio-list {
      margin-top: 60px;
      .single-portfolio-item {
        position: relative;
        z-index: 1;
        @include border-radius(4px);
        margin-bottom: 30px;
        img {
          width: 100%;
          height: 100%;
          @include transition(0.3s);
        }
        .portfolio-hover {
          width: 100%;
          height: 100%;
          color: $white;
          position: relative;
          overflow: hidden;
          @include border-radius(4px);
          .portfolio-link {
            display: block;
          }
        }
        &:hover {
          img {
            @include transform(scale(1.1));
          }
          .portfolio-hover {
            .portfolio-link {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
      .single-portfolio-item.col-lg-4 {
        @media #{$tab_device} {
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }
        @media #{$large_mobile} {
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }
        @media #{$small_mobile} {
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
}

/*====================================================
    about-area
 ====================================================*/
.about-area {
  padding-top: 120px;
  padding-bottom: 115px;
  .section-heading {
    .section__title {
      width: 850px;
      margin-left: auto;
      margin-right: auto;
      @media #{$laptop_m_five} {
        width: auto;
      }
    }
  }
  .about-text {
    width: 760px;
    margin-left: auto;
    margin-right: auto;
    line-height: 28px;
    @media #{$laptop_m_five} {
      width: auto;
    }
  }
  .about-img-wrap {
    margin-top: 55px;
  }
  .about-img {
    @include border-radius(4px);
    @media #{$tab_device} {
      margin-bottom: 30px;
    }
    @media #{$large_mobile} {
      margin-bottom: 30px;
    }
    @media #{$small_mobile} {
      margin-bottom: 30px;
    }
    img {
      width: 100%;
      @include border-radius(4px);
    }
  }
  .about-fun-item {
    margin-top: 60px;
    margin-bottom: 30px;
    @media #{$tab_device} {
      margin-top: 30px;
    }
    @media #{$large_mobile} {
      margin-top: 30px;
    }
    @media #{$small_mobile} {
      margin-top: 30px;
    }
    .col-lg-3 {
      @media #{$tab_device} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media #{$large_mobile} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    .funfact-item {
      background-color: $white;
      padding: 45px 0 38px 0;
      @include border-radius(4px);
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      border-top: 3px solid $theme-color-2;
      margin-bottom: 30px;
      .fun__icon {
        width: 60px;
        height: 60px;
        line-height: 60px;
        @include border-radius(50%);
        background-color: rgba(81, 190, 120, 0.1);
        color: $theme-color-2;
        font-size: $display-7;
        @include transition(0.3s);
      }
      .funfact__title {
        font-size: $display-7;
        font-weight: $font-weight-semi-bold;
        margin-top: 16px;
      }
      .funfact__meta {
        text-transform: capitalize;
        margin-top: 3px;
      }
      &:hover {
        .fun__icon {
          background-color: $theme-color-2;
          color: $white;
        }
      }
    }
    .funfact-item2 {
      border-top-color: $alter-color;
      .fun__icon {
        background-color: rgba(126, 60, 249, 0.1);
        color: $alter-color;
      }
      &:hover {
        .fun__icon {
          background-color: $alter-color;
        }
      }
    }
    .funfact-item3 {
      border-top-color: $alter-color-2;
      .fun__icon {
        background-color: rgba(246, 138, 4, 0.1);
        color: $alter-color-2;
      }
      &:hover {
        .fun__icon {
          background-color: $alter-color-2;
        }
      }
    }
    .funfact-item4 {
      border-top-color: $alter-color-3;
      .fun__icon {
        background-color: rgba(53, 143, 247, 0.1);
        color: $alter-color-3;
      }
      &:hover {
        .fun__icon {
          background-color: $alter-color-3;
        }
      }
    }
  }
}
/*====================================================
    about-area 2
 ====================================================*/
.about-area2 {
  overflow: hidden;
  .section-heading {
    padding-right: 30px;
    @media #{$tab_device} {
     padding-right: 0;
      margin-bottom: 60px;
    }
    @media #{$large_mobile} {
     padding-right: 0;
      margin-bottom: 60px;
    }
    @media #{$small_mobile} {
     padding-right: 0;
      margin-bottom: 60px;
    }
    .section__desc {
      margin-bottom: 10px;
    }
    .theme-btn {
      margin-top: 25px;
    }
  }
  .about-img {
    position: relative;
    z-index: 1;
    &:before,
    &:after {
      position: absolute;
      content: '';
    }
    &:before {
      width: 200px;
      height: 200px;
      bottom: -33px;
      right: -33px;
      background-image: url("../images/dots.png");
      background-size: cover;
      background-position: center;
      opacity: 0.2;
      z-index: -1;
    }
    &:after {
      top: 0;
      left: 50%;
      height: 100%;
      @include transform(translateX(-50%));
      width: 12px;
      background-color: $white;
    }
  }
}
/*-===============================
    FEATURE AREA
===============================-*/
.feature-area {
  padding-bottom: 90px;
  position: relative;
  z-index: 2;
  .feature-content-wrap {
    margin-top: -60px;
    @media #{$device-481}{
      margin-top: 120px;
    }
    @media #{$large_mobile_four} {
      margin-top: 120px;
    }
    @media #{$small_mobile_one} {
      margin-top: 80px;
    }
    .col-sm-6 {
      @media #{$large_mobile} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      @media #{$small_mobile} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .feature-item {
      background-color: $white;
      @include box-shadow(0 0 40px rgba(14,16,48,.05));
      padding: 50px 40px 42px 40px;
      @include border-radius(4px);
      @include transition(0.4s);
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;
      z-index: 1;
      .hover-overlay {
        &:before,
        &:after {
          position: absolute;
          content: '';
          top: 50%;
          left: -90px;
          width: 540px;
          height: 540px;
          z-index: -1;
          transition: all .5s ease;
          @include transform(translateY(100%) rotate(10deg));
          background-color: rgba(255, 255, 255, 0.2);
        }
        &:after {
          top: 70%;
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
      .feature__icon {
        color: $alter-color;
        font-size: $display-5;
        position: relative;
        width: 80px;
        height: 80px;
        line-height: 80px;
        @include border-radius(50%);
        background-color: rgba(126, 60, 249, 0.1);
        text-align: center;
        @include transition(0.4s);
      }
      .feature__title {
        font-weight: $font-weight-semi-bold;
        text-transform: capitalize;
        font-size: $display-10;
        margin-top: 25px;
        margin-bottom: 20px;
        @include transition(0.3s);
      }
      .feature__text {
        line-height: 28px;
        margin-bottom: 20px;
        @include transition(0.4s);
        font-weight: $font-weight-medium;
      }
      .feature__btn {
        text-transform: capitalize;
        color: $alter-color;
        font-weight: $font-weight-medium;
        @include transition(0.4s);
      }
      &:before {
        position: absolute;
        content: '';
        top: 30%;
        left: -90px;
        width: 540px;
        height: 540px;
        background-color: rgba(255, 255, 255, 0.1);
        z-index: -1;
        transition: all .5s ease;
        @include transform(translateY(100%) rotate(10deg));
      }
      &:hover {
        border-color: $theme-color-2;
        background-color: $theme-color-2;
        .feature__title,
        .feature__text,
        .feature__btn {
          color: $white;
        }
        .feature__icon {
          color: $theme-color-2;
          background-color: $white;
        }
        &:before {
          transition-delay: .2s;
          @include transform(translateY(0) rotate(10deg));
        }
        .hover-overlay {
          &:before,
          &:after {
            @include transform(translateY(0) rotate(10deg));
          }
          &:after {
            transition-delay: .1s;
          }
        }
      }
    }
    .feature-item2 {
      .feature__icon {
        color: $alter-color-2;
        background-color: rgba(246, 138, 3, 0.1);
      }
      .feature__btn {
        color: $alter-color-2;
      }
    }
    .feature-item3 {
      .feature__icon {
        color: $alter-color-3;
        background-color: rgba(53, 143, 247, 0.1);
      }
      .feature__btn {
        color: $alter-color-3;
      }
    }
  }
}
/*==================================
    CATEGORY
 ==================================*/
.category-area {
  padding-bottom: 90px;
  .section-heading {
    @media #{$large_mobile} {
      text-align: center;
    }
  }
  .button-shared {
    margin-top: 15px;
  }
  .button-shared.justify-content-end {
    @media #{$tab_device} {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    @media #{$large_mobile} {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    @media #{$small_mobile} {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
  }
  .category-wrapper {
    margin-top: 36px;
    @media #{$tab_device} {
      margin-top: 60px;
    }
    @media #{$large_mobile} {
     margin-top: 60px;
    }
    @media #{$small_mobile} {
     margin-top: 60px;
    }
    .col-lg-4 {
      @media #{$tab_device} {
        width: 50%;
      }
    }
    .category-item {
      @include border-radius(4px);
      position: relative;
      margin-bottom: 30px;
      img {
        width: 100%;
        @include border-radius(4px);
      }
      .category-content {
        position: absolute;
        text-align: center;
        padding-top: 40px;
        top: 50%;
        left: 50%;
        width: 100%;
        z-index: 1;
        @include transform(translate(-50%, -50%));
        color: $white;
        .cat__title {
          color: $white;
          text-transform: capitalize;
          font-weight: $font-weight-medium;
          font-size: $display-10;
          margin-bottom: 4px;
          @include transition(0.3s);
        }
        .cat__meta {
          text-transform: capitalize;
          @include transition(0.3s);
        }
        .cat__link {
          text-transform: capitalize;
          display: inline-block;
          background-color: $white;
          color: $theme-color;
          padding: 8px 14px;
          @include border-radius(4px);
          @include transition(0.3s);
          @include transform(translateY(10px));
          opacity: 0;
          visibility: hidden;
          &:hover {
            background-color: $theme-color-2;
            color: $white;
          }
        }
      }
      &:after {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: $theme-color;
        opacity: .80;
        content: "";
        @include border-radius(4px);
        @include transition(0.3s);
      }
      &:hover {
        .category-content {
          .cat__title {
            margin-bottom: 0;
          }
          .cat__meta {
            opacity: 0;
            visibility: hidden;
          }
          .cat__link {
            opacity: 1;
            visibility: visible;
            @include transform(translateY(-5px));
          }
        }
        &:after {
          opacity: .99;
        }
      }
    }
  }
}
/*==================================
    CATEGORY 2
 ==================================*/
.category-area2 {
  padding-top: 120px;
  padding-bottom: 0;
  .category-wrapper {
    .col-lg-3 {
      @media #{$tab_device} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    .category-item {
      background-color: $white;
      @include border-radius(4px);
      padding: 40px 0 40px 0;
      border: 1px solid rgba(127, 136, 151, 0.2);
      overflow: hidden;
      .category-content {
        color: $theme-color;
        position: inherit;
        top: auto;
        left: auto;
        @include transform(translate(0, 0));
        padding-top: 0;
        a {
          display: block;
        }
        .la {
          color: $theme-color-2;
          width: 70px;
          height: 70px;
          line-height: 70px;
          @include border-radius(50%);
          background-color: rgba(81, 190, 120, 0.1);
          font-size: $display-6;
          @include transition(0.3s);
        }
        .cat__title {
          color: $theme-color;
          margin-top: 20px;
          margin-bottom: 0;
        }
      }
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../images/img31.jpg");
        background-size: cover;
        background-position: center;
        background-color: transparent;
        @include border-radius(4px);
        opacity: 0;
        visibility: hidden;
      }
      &:before,
      &:after {
        @include transform(scale(1.1));
        opacity: 0;
        visibility: hidden;
        @include transition(0.6s);
      }
      &:hover {
        .category-content {
          .la {
            background-color: $white;
          }
          .cat__title {
            color: $white;
          }
        }
        &:after {
          opacity: .80;
        }
        &:before,
        &:after {
          @include transform(scale(1));
          visibility: visible;
        }
        &:before {
          opacity: 1;
        }
      }
    }
  }
}

/*-===============================
    COURSE AREA
===============================-*/
.course-area {
  .course-content-wrapper {
    background-color: $theme-color-5;
    padding-top: 80px;
    padding-bottom: 115px;
  }
  .course-tab-wrap {
    .course-tab-list.nav-tabs {
      border: none;
      margin-top: 36px;
      margin-bottom: 60px;
      li {
        margin: 0 1px;
        a {
          color: $theme-color-4;
          text-transform: capitalize;
          font-weight: $font-weight-medium;
          display: block;
          @include border-radius(4px);
          padding: 8px 17px;
          position: relative;
          overflow: hidden;
          @include transition(0.3s);
          &:after {
            @extend %theme-btn-after;
          }
          &.active {
            background-color: $theme-color-2;
            color: $white;
            &:hover {
              background-color: $theme-color-2;
              color: $white;
            }
          }
          &:hover {
            background-color: rgba(81, 190, 120, 0.1);
            color: $theme-color-2;
          }
        }
      }
    }
  }
  .tab-content {
    .course-block {
      .col-lg-4,
      .col-lg-6 {
        @media #{$tab_device} {
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
  .button-shared {
    margin-top: 28px;
  }
}

/*============ course-block ============*/
.course-block {
  .course-item {
    @include border-radius(4px);
    margin-bottom: 30px;
    @include transition(0.3s);
    .course-img {
      position: relative;
      .course__img {
        display: block;
        img {
          width: 100%;
          @include border-radius(4px 4px 0 0);
        }
      }
      .course-tooltip {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        .tooltip-label {
          background-color: $theme-color-2;
          color: $white;
          text-transform: uppercase;
          font-weight: $font-weight-semi-bold;
          display: inline-block;
          padding: 4px 10px 4px 12px;
          @include border-radius(4px 4px 4px 0);
          font-size: $default-font-5;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 6px;
            z-index: -1;
            background-color: $theme-color-2;
            -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
            @include transform(skew(12deg,0deg));
            @include border-radius(4px 0 0 0);
          }
        }
      }
      &:after {
        position: absolute;
        bottom: 0;
        left: 30px;
        content: '';
        width: 0;
        height: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-bottom: 13px solid $white;
      }
    }
    .course-content {
      border: 1px solid rgba(127, 136, 151, 0.2);
      @include border-radius(0 0 4px 4px);
      padding: 29px 30px 30px 30px;
      border-top: transparent;
      .course__label {
        font-weight: $font-weight-medium;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        .course__label-text {
          background-color: rgba(81, 190, 120, 0.1);
          display: inline-block;
          @include border-radius(4px);
          color: $theme-color-2;
          padding: 3px 10px;
          text-transform: capitalize;
          font-size: $default-font-2;
        }
        .course__collection-icon {
          margin-left: auto;
          color: $theme-color-4;
          font-size: $display-11;
          position: relative;
          &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            content: "\f233";
            font-family: "LineAwesome";
            color: $theme-color-2;
            @include transition(0.3s);
            opacity: 0;
            visibility: hidden;
          }
          &:hover {
            &:after {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
      .course__title {
        font-size: $display-11;
        font-weight: $font-weight-semi-bold;
        margin-top: 10px;
        margin-bottom: 9px;
        line-height: 28px;
        a {
          color: $theme-color;
          @include transition(0.3s);
          &:hover {
            color: $theme-color-2;
          }
        }
      }
      .course__author {
        a {
          color: $theme-color-4;
          text-transform: capitalize;
          font-weight: $font-weight-medium;
          @include transition(0.3s);
          &:hover {
            color: $theme-color-2;
          }
        }
      }
      .rating-wrap {
        margin-top: 8px;
        font-size: $default-font-2;
        margin-bottom: 20px;
        .review-stars {
          li {
            margin-right: 2px;
            span {
              color: $alter-color-2;
            }
          }
        }
        .star-rating-wrap {
          color: $theme-color;
          font-weight: $font-weight-medium;
          .star__rating {
            margin: 0 1px 0 3px;
          }
        }
      }
      .course-meta {
        margin-bottom: 25px;
        .course__list {
          li {
            width: 100%;
            border-right: 1px solid rgba(127, 136, 151, 0.2);
            padding: 0 15px;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              border-right: none;
              padding-right: 0;
              text-align: right;
            }
            span {
              display: block;
              &.meta__date {
                color: $theme-color;
                font-weight: $font-weight-medium;
              }
              &.meta__text {
                font-weight: $font-weight-medium;
                font-size: $default-font-2;
              }
            }
          }
        }
      }
      .course-price-wrap {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        .course__price {
          color: $theme-color;
          font-weight: $font-weight-semi-bold;
          font-size: $display-12;
          .course__before-price {
            font-size: $default-font;
            margin-right: 3px;
            color: $theme-color-4;
            text-decoration: line-through;
          }
        }
        .course__btn {
          margin-left: auto;
          color: $theme-color-4;
          font-weight: $font-weight-medium;
          @include transition(0.3s);
          &:hover {
            color: $theme-color-2;
          }
        }
      }
    }
    &:hover {
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    }
  }
}

/*-===============================
    COURSE AREA 2
===============================-*/
.course-area2 {
  padding-top: 120px;
  @media #{$large_mobile_three} {
    padding-bottom: 80px;
  }
  .course-content-wrapper {
    padding-top: 36px;
    padding-bottom: 0;
    background-color: transparent;
  }
  .owl-nav {
    div {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      @include border-radius(50%);
      background-color: $white;
      border: 1px solid rgba(127, 136, 151, 0.1);
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      font-size: $display-13;
      @include transition(0.3s);
      @media #{$laptop_m} {
        top: 38%;
        @include transform(translateY(0));
      }
      @media #{$large_mobile_three} {
        top: auto;
        bottom: -50px;
      }
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
      &.owl-prev {
        left: -100px;
        @media #{$laptop_m} {
          left: -25px;
        }
        @media #{$large_mobile_three} {
          left: 200px;
        }
        @media #{$large_mobile_four} {
          left: 160px;
        }
        @media #{$small_mobile_one} {
          left: 130px;
        }
        @media #{$device-384} {
          left: 110px;
        }
        @media #{$small_mobile_three} {
          left: 85px;
        }
      }
      &.owl-next {
        left: auto;
        right: -100px;
        @media #{$laptop_m} {
          right: -25px;
        }
        @media #{$large_mobile_three} {
          right: 200px;
        }
        @media #{$large_mobile_four} {
          right: 160px;
        }
        @media #{$small_mobile_one} {
          right: 130px;
        }
        @media #{$device-384} {
          right: 110px;
        }
        @media #{$small_mobile_three} {
         right: 85px;
        }
      }
    }
  }
}
/*-===============================
    COURSE AREA 3
===============================-*/
.course-area3 {
  .course-wrapper {
    background-color: $theme-color-5;
    .course-tab-list.nav-tabs {
      margin-bottom: 30px;
      li {
        a {
          @include border-radius(30px);
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }
    }
  }
  .course-content-wrapper {
    background-color: transparent;
    padding-top: 30px;
  }
}
/*-===============================
    COURSE AREA 4
===============================-*/
.course-area4 {
  padding-top: 110px;
  .course-tab-wrap {
    background-color: $white;
    border: 1px solid rgba(127, 136, 151, 0.2);
    @include border-radius(4px);
    padding: 20px 20px;
    .course-tab-list.nav-tabs {
      margin: 0;
      @media #{$large_mobile_four} {
        margin-bottom: 30px;
        -ms-flex-pack: center;
        justify-content: center;
      }
      li {
        margin-left: 0;
        margin-right: 5px;
        &:nth-child(2) {
          margin-right: 15px;
        }
        a {
          font-size: $display-11;
          padding: 0;
          span {
            padding: 7px 12px;
            z-index: 1;
          }
        }
      }
    }
    .course-filter {
      .courses-ordering {
        @media #{$large_mobile_four} {
          width: 100%;
        }
      }
    }
  }
  .course-tab-wrap.d-flex {
    @media #{$large_mobile_four} {
      display: block !important;
    }
  }
  .course-item-wrap {
    .tab-content {
      .course-list-block {
        .course-item {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          @media #{$tab_device} {
            width: 100%;
          }
          @media #{$large_mobile} {
            width: 100%;
          }
          @media #{$small_mobile_one} {
            display: block;
          }
          .course-img {
            width: 40%;
            @media #{$small_mobile_one} {
              width: 100%;
            }
            .course__img {
              img {
                height: 304px;
                object-fit: cover;
                @include border-radius(4px 0 0 4px);
                @media #{$large_mobile} {
                  height: 355px;
                }
                @media #{$small_mobile_one} {
                  height: auto;
                  @include border-radius(4px 4px 0 0);
                }
              }
            }
            &:after {
              bottom: auto;
              top: 35px;
              left: auto;
              right: -7px;
              @include transform(rotate(-90deg));
              @media #{$small_mobile_one} {
                top: auto;
                left: 30px;
                bottom: 0;
                right: auto;
                @include transform(rotate(0));
              }
            }
          }
          .course-content {
            width: 60%;
            border-left: transparent;
            border-top: 1px solid rgba(127, 136, 151, 0.2);
            @include border-radius(0 4px 4px 0);
            @media #{$small_mobile_one} {
              width: 100%;
            }
            @media #{$small_mobile_one} {
              border-top: transparent;
              @include border-radius(0 0 4px 4px);
              border-left: 1px solid rgba(127, 136, 151, 0.2);
            }
          }
        }
      }
      .course-block {
        .course-item {
          @media #{$tab_device} {
            width: 100%;
          }
          @media #{$large_mobile} {
            width: 100%;
          }
        }
      }
    }
  }
  .course-content-wrapper {
    padding-top: 30px;
    background-color: transparent;
  }
}
/*-========= course-filter =========-*/
.course-filter {
  .courses-ordering {
    position: relative;
    width: 200px;
    .target-course {
      width: 100%;
      border: 1px solid rgba(127, 136, 151, 0.2);;
      padding: 15px 0 15px 20px;
      font-size: $default-font;
      color: $theme-color-4;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      cursor: pointer;
      outline: none;
      @include border-radius(4px);
      background-color: transparent;
      font-weight: $font-weight-medium;
    }
    &:before {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      font-family: "LineAwesome";
      font-size: $default-font-2;
      color: $theme-color-4;
      pointer-events: none;
      display: inline-block;
      content: "\f110";
      right: 13px;
    }
  }
}

/*-===============================
    FUNFACT AREA
===============================-*/
.funfact-area {
  padding-top: 103px;
  padding-bottom: 115px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @media #{$tab_device} {
    padding-bottom: 54px;
  }
  @media #{$large_mobile} {
    padding-bottom: 54px;
  }
  @media #{$small_mobile} {
    padding-bottom: 54px;
  }
  .funfact-row {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @media #{$tab_device} {
      flex-wrap: wrap;
    }
    @media #{$large_mobile} {
      flex-wrap: wrap;
    }
    @media #{$small_mobile} {
      flex-wrap: wrap;
    }
  }
  .funfact-item {
    @media #{$tab_device} {
      margin-bottom: 60px;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      display: -webkit-box;
      display: -ms-flex;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      padding-left: 15px;
      padding-right: 15px;
    }
    @media #{$large_mobile} {
      margin-bottom: 60px;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      display: -webkit-box;
      display: -ms-flex;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      padding-left: 15px;
      padding-right: 15px;
    }
    @media #{$small_mobile} {
      margin-bottom: 60px;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      display: -webkit-box;
      display: -ms-flex;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      padding-left: 15px;
      padding-right: 15px;
    }
    .funfact-inner-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      position: relative;
      .la {
        position: absolute;
        top: -25px;
        left: -25px;
        font-size: $display-3;
        color: rgba(127, 136, 151, 0.1);
      }
      .funfact__title {
        font-size: $display-5;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 10px;
        color: $alter-color;
      }
      .funfact__meta {
        font-size: $default-font-2;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
    &:nth-child(2) {
      .funfact-inner-item {
        border-color: rgba(246, 138, 3, 0.8);
        .funfact__title {
          color: $alter-color-2;
        }
      }
    }
    &:nth-child(3) {
      .funfact-inner-item {
        border-color: rgba(53, 143, 247, 0.2);
        .funfact__title {
          color: $alter-color-3;
        }
      }
    }
    &:nth-child(4) {
      .funfact-inner-item {
        border-color: rgba(56, 187, 12, 0.2);
        .funfact__title {
          color: $alter-color-4;
        }
      }
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 200px;
    height: 200px;
    background-position: center;
    background-image: url("../images/dots.png");
    @include border-radius(50%);
    @include transform(rotate(10deg));
    background-size: cover;
    opacity: 0.2;
  }
  &:after {
    top: -80px;
    left: auto;
    bottom: auto;
    right: 0;
  }
}
/*-===============================
    SPEAKER AREA
===============================-*/
.speaker-area {
  padding-top: 120px;
  padding-bottom: 115px;
  position: relative;
  .section-heading {
    .section__title {
      width: 650px;
      margin-left: auto;
      margin-right: auto;
      @media #{$large_mobile} {
       width: auto;
      }
      @media #{$small_mobile} {
       width: auto;
      }
    }
  }
  .speaker-content-wrap {
    margin-top: 36px;
    .speaker-item {
      margin-bottom: 30px;
      background-color: $white;
      @include box-shadow(0 0 40px rgba(14,16,48,.05));
      border: 1px solid rgba(127, 136, 151, 0.1);
      @include border-radius(4px);
      position: relative;
      z-index: 1;
      @include transition(0.3s ease-in);
      .speaker-img-box {
        position: relative;
        img {
          width: 100%;
          @include border-radius(4px 4px 0 0);
          @include box-shadow(0 0 40px rgba(14,16,48,.05));
        }
        .speaker__profile {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 100%;
          @include transform(translateX(-50%));
          opacity: 0;
          visibility: hidden;
          @include transition(0.3s);
          text-align: center;
          li {
            display: inline-block;
            a {
              color: $theme-color;
              display: block;
              width: 40px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              @include border-radius(50%);
              background-color: $white;
              @include transition(0.3s);
              position: relative;
              z-index: 1;
              &:after {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: $theme-color-2;
                z-index: -1;
                @include border-radius(50%);
                @include transform(scale(0.6));
                @include transition(0.3s ease-in-out);
                opacity: 0;
                visibility: hidden;
              }
              &:hover {
                color: $white;
                &:after {
                  @include transform(scale(1));
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
        &:after {
          position: absolute;
          content: '';
          bottom: -1px;
          left: 40px;
          width: 0;
          height: 0;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          border-bottom: 13px solid $white;
        }
      }
      .speaker-detail {
        padding: 32px 40px 38px 40px;
        .speaker-title-box {
          position: relative;
          z-index: 1;
          .speaker__title {
            font-size: $display-9;
            text-transform: capitalize;
            font-weight: $font-weight-semi-bold;
            display: inline-block;
            margin-bottom: 3px;
            a {
              color: $theme-color;
              @include transition(0.3s);
              display: block;
              position: relative;
              &:hover {
                color: $theme-color-2;
              }
            }
          }
          .speaker__meta {
            text-transform: capitalize;
            font-weight: $font-weight-medium;
            margin-bottom: 25px;
          }
          .speaker__text {
            line-height: 28px;
            margin-bottom: 35px;
          }
          .speaker__link {
            color: $theme-color-4;
            text-transform: capitalize;
            font-weight: $font-weight-medium;
            display: inline-block;
            padding: 9px 20px;
            background-color: rgba(127, 136, 151, 0.1);
            @include border-radius(30px);
            @include transition(0.3s);
            border: 1px solid rgba(127, 136, 151, 0.2);
            &:hover {
              background-color: $theme-color-2;
              color: $white;
            }
          }
        }
      }
      &:hover {
        .speaker-img-box {
          .speaker__profile {
            opacity: 1;
            visibility: visible;
            bottom: 30px;
          }
        }
        .speaker-title-box {
          .speaker__link {
            background-color: $theme-color-2;
            color: $white;
            border-color: $theme-color-2;
          }
        }
      }
    }
    .col-lg-4 {
      @media #{$tab_device} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .button-shared {
    margin-top: 30px;
  }
}
/*-===============================
    INSTRUCTOR-AREA
===============================-*/
.instructor-area {
  .speaker-content-wrap {
    margin-top: 0;
  }
}

/*===================================
    TESTIMONIAL AREA
=====================================*/
.testimonial-area {
  padding-top: 119px;
  padding-bottom: 114px;
  background-color: $theme-color-5;
  .section-heading {
   .section__title {
     width: 500px;
     margin-left: auto;
     margin-right: auto;
     @media #{$large_mobile} {
       width: auto;
     }
     @media #{$small_mobile} {
       width: auto;
     }
   }
  }
  .testimonial-wrap {
    margin-top: 35px;
    .testimonial-item {
      position: relative;
      padding: 40px 48px 40px 48px;
      @include border-radius(2px);
      background-color: $white;
      @include box-shadow(0 0 40px rgba(0,0,0,.01));
      @include transition(0.3s);
      border: 1px solid rgba(127, 136, 151, 0.2);
      @media #{$small_mobile_four} {
        padding: 30px;
      }
      .testimonial__desc {
        margin-top: 20px;
        .testimonial__desc-desc {
          line-height: 30px;
          @include transition(0.3s);
        }
        &:after {
          position: absolute;
          content: "”";
          font-size: 100px;
          line-height: 0;
          bottom: 20px;
          right: 20px;
          color: $theme-color-4;
          font-weight: $font-weight-bold;
          @include transition(0.3s);
          opacity: 0.1;
        }
      }
      .testimonial__name {
        position: relative;
        padding-left: 64px;
        padding-top: 14px;
        padding-bottom: 30px;
        line-height: 18px;
        border-bottom: 1px solid rgba(127, 136, 151, 0.2);
        img {
          position: absolute;
          width: 54px;
          height: 54px;
          overflow: hidden;
          left: 0;
          top: 8px;
          @include border-radius(50%);
        }
        .testimonial__name-title {
          font-size: $display-12;
          line-height: 20px;
          font-weight: $font-weight-semi-bold;
          @include transition(0.3s);
          margin-bottom: 5px;
        }
        .testimonial__name-meta {
          font-size: $default-font-2;
          line-height: 14px;
          text-transform: capitalize;
          font-weight: $font-weight-medium;
          @include transition(0.3s);
        }
        .testimonial__name-rating {
          color: $alter-color-2;
          font-size: $default-font-2;
          margin-left: 6px;
          .la {
            margin: 0 -1px;
          }
        }
      }
      &:hover {
        background-color: $theme-color-2;
        border-color: $theme-color-2;
        .testimonial__desc .testimonial__desc-desc,
        .testimonial__name .testimonial__name-title,
        .testimonial__name .testimonial__name-meta {
          color: $white;
        }
        .testimonial__name {
          border-bottom-color: rgba(255, 255, 255, 0.1);
        }
        .testimonial__desc {
          &:after {
            color: $white;
          }
        }
      }
    }
    .owl-dots {
      text-align: center;
      margin-top: 60px;
      div {
        width: 14px;
        height: 14px;
        line-height: 14px;
        background-color: rgba(127, 136, 151, 0.4);
        @include border-radius(50%);
        display: inline-block;
        margin: 0 2px;
        position: relative;
        @include transition(0.3s);
        @include transform(scale(0.7));
        &.active,
        &:hover {
          background-color: $theme-color-2;
          @include transform(scale(1));
        }
      }
    }
  }
}


/*=== rotateme ===*/
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*-===============================
   perticles-js
===============================-*/
#perticles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/*-===============================
    GET-START AREA
===============================-*/
.get-start-area {
  position: relative;
  padding-top: 120px;
  padding-bottom: 115px;
  background-image: url("../images/img7.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: 2;
  overflow: hidden;
  .section-heading {
    .section__title {
      font-size: $display-3;
      line-height: 80px;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      @media #{$tab_device} {
        font-size: $display-4;
        line-height: 75px;
      }
      @media #{$large_mobile} {
        font-size: $display-5;
        line-height: 60px;
      }
      @media #{$small_mobile} {
        font-size: $display-5;
        line-height: 60px;
      }
      @media #{$small_mobile_one} {
        font-size: $display-6;
        line-height: 50px;
      }
    }
    .get-start-btn {
      margin-top: 38px;
    }
  }
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background-color: $theme-color;
    opacity: .95;
  }
  .box-icons,
  .box-icons2 {
    position: absolute;
    left: 100px;
    bottom: 75px;
    z-index: 1;
    @media #{$large_mobile_three} {
      display: none;
    }
    div {
      position: absolute;
      border: 2px solid rgba(255,255,255,0.10);
      width: 19px;
      height: 19px;
      @include border-radius(50%);
      &.box-one {
        left: -40px;
        bottom: 50px;
      }
      &.box-two {
        left: 0px;
        top: 0;
        width: 46px;
        height: 46px;
      }
      &.box-three {
        left: 50px;
        top: -150px;
        width: 80px;
        height: 80px;
      }
      &.box-four {
        left: -85px;
        top: -258px;
        width: 97px;
        height: 97px;
      }
      &.box-five {
        left: 100px;
        top: 2px;
        width: 40px;
        height: 40px;
      }
    }
  }
  .box-icons2 {
    left: auto;
    right: 240px;
    bottom: auto;
    top: 100px;
    @include transform(rotate(90deg));
  }
}
/*-===============================
    GET-START AREA 2
===============================-*/
.get-start-area2 {
  @media #{$tab_device} {
    text-align: center;
    padding-bottom: 150px;
  }
  @media #{$large_mobile} {
    text-align: center;
    padding-bottom: 150px;
  }
  @media #{$small_mobile} {
    text-align: center;
    padding-bottom: 150px;
  }
  .col-lg-10 {
    @media #{$tab_device} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .section-heading {
    .section__title {
      font-size: $display-5;
      line-height: 60px;
      width: 690px;
      @media #{$tab_device} {
       width: auto;
      }
      @media #{$large_mobile} {
       width: auto;
      }
      @media #{$small_mobile} {
       width: auto;
      }
      @media #{$small_mobile_three} {
        font-size: $display-6;
        line-height: 50px;
      }
    }
  }
  .box-icons {
    top: 75px;
    bottom: auto;
    div {
      &.box-three {
        top: 150px;
      }
      &.box-four {
        top: 258px;
      }
    }
  }
  .box-icons2 {
    top: auto;
    bottom: 75px;
    div {
      &.box-five {
        left: -130px;
      }
    }
  }
  .promo-btn {
    @media #{$tab_device} {
      margin-top: 60px;
    }
    @media #{$large_mobile} {
      margin-top: 60px;
    }
    @media #{$small_mobile} {
      margin-top: 60px;
    }
    .video-play-btn {
      display: block;
      width: 130px;
      height: 130px;
      background-color: $white;
      text-align: center;
      @include border-radius(50%);
      line-height: 130px;
      color: $theme-color;
      font-size: $display-4;
      @include transition(0.3s);
      position: relative;
      z-index: 1;
      @media #{$small_mobile_three} {
        width: 100px;
        height: 100px;
        line-height: 100px;
        font-size: $display-5;
      }
      &:before,
      &:after {
        position: absolute;
        content: '';
        top: -25px;
        left: -25px;
        bottom: -25px;
        right: -25px;
        background-color: rgba(255,255,255, 0.2);
        @include border-radius(50%);
        -webkit-animation: play-button-pulse 1.8s linear infinite;
        -moz-animation: play-button-pulse 1.8s linear infinite;
        animation: play-button-pulse 1.8s linear infinite;
        opacity: 0;
        z-index: -1;
      }
      &:after {
        -webkit-animation-delay: .6s;
        -moz-animation-delay: .6s;
        animation-delay: .6s;
      }
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
    }
  }
  .promo-btn.justify-content-end {
    @media #{$tab_device} {
      -ms-flex-pack: center!important;
      justify-content: center!important;
    }
    @media #{$large_mobile} {
      -ms-flex-pack: center!important;
      justify-content: center!important;
    }
    @media #{$small_mobile} {
      -ms-flex-pack: center!important;
      justify-content: center!important;
    }
  }
}

/*-===============================
    BENEFIT AREA
===============================-*/
.benefit-area {
  position: relative;
  z-index: 1;
  padding-bottom: 115px;
  overflow: hidden;
  .benefit-heading {
    padding-top: 120px;
  }
  .section-heading {
    .section__desc {
      margin-bottom: 40px;
    }
  }
  .benefit-course-box {
    .col-lg-4 {
      @media #{$tab_device} {
       -ms-flex: 0 0 33%;
        flex: 0 0 33%;
        max-width: 33%;
      }
      @media #{$large_mobile} {
        width: 50%;
      }
    }
    .benefit-item {
      margin-bottom: 48px;
      @media #{$small_mobile} {
        text-align: center;
      }
      .benefit__icon {
        color: $alter-color-4;
        font-size: $display-7;
        position: relative;
        width: 50px;
        height: 50px;
        line-height: 50px;
        @include border-radius(12px);
        background-color: rgba(56,187, 12, 0.1);
        text-align: center;
        @include transition(0.4s);
      }
      .benefit__title {
        margin-top: 16px;
        font-weight: $font-weight-semi-bold;
        font-size: $display-12;
      }
      &:hover {
        .benefit__icon {
          background-color: $theme-color-2;
          color: $white;
        }
      }
    }
    .benefit-item2 {
      .benefit__icon {
        color: $alter-color-5;
        background-color: rgba(221,73, 61, 0.1);
      }
    }
    .benefit-item3 {
      .benefit__icon {
        color: $alter-color-6;
        background-color: rgba(62,91, 153, 0.1);
      }
    }
  }
  .get-start-btn {
    @media #{$small_mobile} {
      text-align: center;
    }
  }
  .benefit-img {
    @media #{$tab_device} {
      margin-top: 55px;
    }
    @media #{$large_mobile} {
      margin-top: 55px;
    }
    @media #{$small_mobile} {
      margin-top: 55px;
    }
    img {
      width: 100%;
      position: absolute;
      @include border-radius(4px);
      @media #{$tab_device} {
        position: inherit;
      }
      @media #{$large_mobile} {
        position: inherit;
      }
      @media #{$small_mobile} {
        position: inherit;
      }
      &:nth-child(1) {
        top: 0;
        right: 0;
        margin-right: -352px;
        @include border-radius(0);
        @media #{$tab_device} {
          margin-right: auto;
          right: auto;
          top: auto;
          @include border-radius(4px);
          margin-bottom: 30px;
        }
        @media #{$large_mobile} {
          margin-right: auto;
          right: auto;
          top: auto;
          @include border-radius(4px);
          margin-bottom: 30px;
        }
        @media #{$small_mobile} {
          margin-right: auto;
          right: auto;
          top: auto;
          @include border-radius(4px);
          margin-bottom: 30px;
        }
      }
      &:nth-child(2) {
        margin-top: 230px;
        margin-left: 60px;
        border: 20px solid $white;
        @media #{$tab_device} {
          margin-top: auto;
          margin-left: auto;
          border: none;
        }
        @media #{$large_mobile} {
          margin-top: auto;
          margin-left: auto;
          border: none;
        }
        @media #{$small_mobile} {
          margin-top: auto;
          margin-left: auto;
          border: none;
        }
      }
    }
  }
  &:after {
    position: absolute;
    content: '';
    right: 200px;
    bottom: 45px;
    width: 220px;
    height: 341px;
    background-image: url("../images/dots.png");
    background-position: center;
    background-size: cover;
    z-index: -1;
    opacity: 0.1;
  }
}
/*-===============================
    BENEFIT AREA 2
===============================-*/
.benefit-area2 {
  padding-top: 120px;
  .benefit-heading {
    padding-top: 0;
    @media #{$laptop_m_four} {
      padding-left: 30px;
    }
    @media #{$tab_device} {
     padding-left: 0;
    }
    @media #{$large_mobile} {
     padding-left: 0;
    }
    @media #{$small_mobile} {
     padding-left: 0;
    }
    .benefit-course-box {
      .benefit-item {
        .benefit__icon {
          @include border-radius(50%);
        }
      }
    }
  }
  .benefit-img {
    @media #{$tab_device} {
      margin-top: 0;
      margin-bottom: 60px;
    }
    @media #{$large_mobile} {
      margin-top: 0;
      margin-bottom: 60px;
    }
    @media #{$small_mobile} {
      margin-top: 0;
      margin-bottom: 60px;
    }
    img {
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.2));
      @include border-radius(4px);
      @media #{$laptop_m_four} {
        position: inherit;
      }
      &:nth-child(1) {
        left: 15px;
        margin-left: 0;
        width: 59%;
        @include border-radius(4px);
        @media #{$laptop_m_four} {
          width: 100%;
          left: auto;
          right: auto;
          margin-right: 0;
        }
        @media #{$tab_device} {
          margin-bottom: 0;
        }
        @media #{$large_mobile} {
          margin-bottom: 0;
        }
        @media #{$small_mobile} {
          margin-bottom: 0;
        }
      }
      &:nth-child(2) {
        margin-left: 241px;
        margin-top: 40px;
        width: 40%;
        border: none;
        @media #{$laptop_m_four} {
          width: 100%;
          margin-left: inherit;
          margin-top: 30px;
        }
      }
      &:nth-child(3) {
        bottom: 5px;
        right: 15px;
        width: 60%;
        margin-right: 90px;
        @media #{$laptop_m_four} {
          display: none;
        }
        @media #{$tab_device} {
          display: block;
          width: 100%;
          margin-right: 0;
          bottom: auto;
          right: auto;
          margin-top: 30px;
          margin-bottom: 30px;
        }
        @media #{$large_mobile} {
          display: block;
          width: 100%;
          margin-right: 0;
          bottom: auto;
          right: auto;
          margin-top: 30px;
          margin-bottom: 30px;
        }
        @media #{$small_mobile} {
          display: block;
          width: 100%;
          margin-right: 0;
          bottom: auto;
          right: auto;
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
      &:nth-child(4) {
        bottom: 46px;
        left: 15px;
        width: 40%;
        @media #{$laptop_m_four} {
          display: none;
        }
        @media #{$tab_device} {
          display: block;
          width: 100%;
          left: auto;
          bottom: auto;
        }
        @media #{$large_mobile} {
          display: block;
          width: 100%;
          left: auto;
          bottom: auto;
        }
        @media #{$small_mobile} {
          display: block;
          width: 100%;
          left: auto;
          bottom: auto;
        }
      }
    }
  }
  &:after {
    right: auto;
    left: 290px;
    bottom: 83px;
  }
}

/*-===============================
    REGISTER AREA
===============================-*/
.register-area {
  padding-top: 120px;
  padding-bottom: 119px;
  position: relative;
  z-index: 1;
  .register-heading {
    padding-left: 90px;
    @media #{$tab_device} {
      margin-top: 60px;
      padding-left: 0;
    }
    @media #{$large_mobile} {
      margin-top: 60px;
      padding-left: 0;
    }
    @media #{$small_mobile} {
      margin-top: 60px;
      padding-left: 0;
    }
  }
  .section-heading {
    .register__desc {
      margin-bottom: 18px;
    }
  }
  .register-wrap {
    background-color: $white;
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    @include border-radius(4px);
    position: relative;
    .contact-title {
      background-color: $theme-color-2;
      color: $white;
      font-weight: $font-weight-semi-bold;
      padding: 30px 40px 30px 40px;
      @include border-radius(4px 4px 0 0);
      position: relative;
      @media #{$small_mobile_three} {
        padding: 20px;
        font-size: $display-10;
      }
    }
    form {
      padding: 40px;
      @media #{$small_mobile_three} {
        padding: 20px;
      }
    }
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      top: 0;
      left: 0;
      height: 2px;
      background-color: rgba(127, 136, 151, 0.1);
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
    right: 55px;
    bottom: 91px;
    width: 200px;
    height: 200px;
    background-image: url("../images/dots.png");
    background-position: center;
    background-size: cover;
    z-index: -1;
    @include border-radius(50%);
    opacity: 0.3;
    @media #{$tab_device} {
      width: 100px;
      height: 100px;
    }
    @media #{$large_mobile} {
      width: 100px;
      height: 100px;
    }
    @media #{$small_mobile} {
      width: 100px;
      height: 100px;
    }
  }
  &:after {
    right: auto;
    left: 280px;
    bottom: 71px;
    @media #{$tab_device} {
      left: 0;
      bottom: 0;
    }
    @media #{$large_mobile} {
      left: 0;
      bottom: 0;
    }
    @media #{$small_mobile} {
      left: 0;
      bottom: 0;
    }
  }
}
/*-===============================
    REGISTER AREA 2
===============================-*/
.register-area2 {
  .register-heading {
    padding-left: 0;
    padding-right: 90px;
    @media #{$tab_device} {
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 60px;
    }
    @media #{$large_mobile} {
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 60px;
    }
    @media #{$small_mobile} {
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 60px;
    }
  }
  &:after {
    left: 50px;
  }
  &:before {
    right: 280px;
  }
}
/*====================================================
   clientlogo-area
 ====================================================*/
.clientlogo-area {
  padding-top: 120px;
  padding-bottom: 102px;
  text-align: center;
  position: relative;
  overflow: hidden;
  .client-logo {
    margin-top: 30px;
    .client-logo-item {
      display: inline-block;
      img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        @include transition(0.3s);
      }
      &:hover {
        img {
          -webkit-filter: grayscale(0);
          filter: grayscale(0);
        }
      }
    }
  }
  .stroke-line,
  .stroke-line2 {
    position: absolute;
    top: 175px;
    left: 50px;
    width: 200px;
    display: inline-block;
    @include transform(rotate(-55deg));
    opacity: 0.1;
    .stroke__line {
      background-color: $theme-color-4;
      height: 2px;
      display: block;
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 18px;
      }
    }
  }
  .stroke-line2 {
    left: auto;
    right: 50px;
    bottom: 0;
    top: auto;
    @include transform(rotate(55deg));
  }
}
/*-===============================
    BLOG AREA
===============================-*/
.blog-area {
  position: relative;
  z-index: 1;
  padding-top: 120px;
  .blog-post-wrapper {
    margin-top: 37px;
    .blog-post-item {
      @include border-radius(4px);
      margin-bottom: 30px;
      @media #{$tab_device} {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
      .blog-post-img {
        position: relative;
        .blog__img {
          width: 100%;
          @include border-radius(4px 4px 0 0);
        }
        .blog__date {
          position: absolute;
          top: 20px;
          left: 20px;
          background-color: $white;
          text-transform: capitalize;
          font-weight: $font-weight-medium;
          color: $theme-color;
          display: inline-block;
          padding: 8px 12px;
          @include border-radius(4px);
          z-index: 1;
          @include transition(0.3s);
        }
        &:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $theme-color;
          opacity: 0.2;
          @include transition(0.3s);
          @include border-radius(4px 4px 0 0);
        }
        &:after {
          position: absolute;
          content: '';
          bottom: -1px;
          left: 30px;
          width: 0;
          height: 0;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          border-bottom: 13px solid $white;
        }
      }
      .post-body {
        background-color: $white;
        @include box-shadow(0 0 40px rgba(14, 16, 48, 0.05));
        border: 1px solid rgba(127, 136, 151, 0.2);
        border-top: transparent;
        @include border-radius(0 0 4px 4px);
        padding: 30px 30px 35px 30px;
        .blog-title {
          margin-bottom: 20px;
          .blog__title {
            display: block;
            color: $theme-color;
            font-size: $display-11;
            font-weight: $font-weight-semi-bold;
            line-height: 28px;
            @include transition(0.3s);
            &:hover {
              color: $theme-color-2;
            }
          }
        }
        .blog__panel {
          li {
            margin-right: 14px;
            position: relative;
            &:after {
              position: absolute;
              content: '.';
              bottom: 3px;
              right: -10px;
              color: $theme-color-4;
              font-size: $display-10;
            }
            a {
              color: $theme-color-4;
              margin-left: 3px;
              display: inline-block;
              @include transition(0.3s);
              &:hover {
                color: $theme-color-2;
              }
            }
            &:last-child {
              margin-right: 0;
              &:after {
                display: none;
              }
            }
          }
        }
      }
      &:hover {
        .blog-post-img {
          .blog__date {
            background-color: $theme-color-2;
            color: $white;
          }
          &:before {
            opacity: 0.5;
          }
        }
      }
    }
  }
  &:before,
  &:after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 536px;
    z-index: -1;
    @media #{$laptop_m_four} {
      height: 484px;
    }
    @media #{$tab_device} {
      height: 611px;
    }
    @media #{$large_mobile} {
      height: 629px;
    }
    @media #{$small_mobile_one} {
      height: 595px;
    }
    @media #{$small_mobile_four} {
      height: 561px;
    }
    @media #{$small_mobile_three} {
      height: 525px;
    }
  }
  &:before {
    background-image: url("../images/img8.jpg");
    background-size: cover;
    background-position: center;
  }
  &:after {
    background-color: $theme-color;
    opacity: 0.90;
  }
  .owl-dots {
    text-align: center;
    margin-top: 26px;
    div {
      width: 14px;
      height: 14px;
      line-height: 14px;
      background-color: rgba(127, 136, 151, 0.4);
      @include border-radius(50%);
      display: inline-block;
      margin: 0 2px;
      position: relative;
      @include transition(0.3s);
      @include transform(scale(0.7));
      &.active,
      &:hover {
        background-color: $theme-color-2;
        @include transform(scale(1));
      }
    }
  }
}
/*-===============================
    BLOG AREA 2
===============================-*/
.blog-area2 {
  padding-bottom: 118px;
  &:before,
  &:after{
    top: auto;
    bottom: 0;
    height: 360px;
    @media #{$laptop_m_four} {
      height: 410px;
    }
    @media #{$tab_device} {
      height: 360px;
    }
    @media #{$large_mobile} {
      height: 360px;
    }
    @media #{$small_mobile} {
      height: 360px;
    }
    @media #{$small_mobile_three} {
      height: 410px;
    }
  }
}
/*-===============================
    BLOG AREA 3
===============================-*/
.blog-area3 {
  padding-bottom: 120px;
  @media #{$tab_device} {
   padding-bottom: 0;
  }
  .blog-post-wrapper {
    margin-top: 0;
    .col-lg-4 {
      @media #{$tab_device} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    .blog-post-item {
      @media #{$tab_device} {
        width: auto;
      }
    }
  }
  &:before,
  &:after{
    display: none;
  }
}
/*-===============================
    BLOG AREA 4
===============================-*/
.blog-area4 {
  padding-top: 120px;
  padding-bottom: 120px;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-bottom: 120px;
  }
  @media #{$small_mobile} {
    padding-bottom: 120px;
  }
  .blog-content-wrap {
    .blog-item {
      .blog-img-box {
        position: relative;
        @include border-radius(4px 4px 0 0);
        img {
          width: 100%;
          @include border-radius(4px 4px 0 0);
        }
        .blog__date {
          position: absolute;
          top: 20px;
          left: 20px;
          background-color: $white;
          text-transform: capitalize;
          font-weight: $font-weight-medium;
          color: $theme-color;
          display: inline-block;
          padding: 8px 12px;
          @include border-radius(4px);
          z-index: 1;
          @include transition(0.3s);
        }
        &:after {
          position: absolute;
          content: '';
          bottom: -1px;
          left: 30px;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-bottom: 20px solid $white;
        }
      }
      .blog-content {
        border: 1px solid rgba(127, 136, 151, 0.2);
        border-top: 0;
        @include border-radius(0 0 4px 4px);
        padding: 30px 30px 35px 30px;
        margin-bottom: 60px;
        .blog__title {
          font-size: $display-6;
          line-height: 50px;
          color: $theme-color;
          font-weight: $font-weight-semi-bold;
          @media #{$large_mobile_four} {
            font-size: $display-7;
            line-height: 35px;
          }
        }
        .blog__list {
          margin-top: 10px;
          margin-bottom: 30px;
          padding-bottom: 30px;
          border-bottom: 1px solid rgba(127, 136, 151, 0.2);
          li {
            display: inline-block;
            margin-right: 10px;
            &:last-child {
              margin-right: 0;
            }
            .la {
              margin-right: 5px;
            }
            a {
              color: $theme-color-4;
              @include transition(0.3s);
              &:hover {
                color: $theme-color-2;
              }
            }
          }
        }
        .blog__desc {
          line-height: 28px;
          margin-bottom: 15px;
        }
        .content-requirement {
          .blog__title {
            font-size: $display-10;
          }
          .content__list {
            margin-bottom: 20px;
            li {
              position: relative;
              padding-left: 15px;
              margin-bottom: 10px;
              &:last-child {
                margin-bottom: 0;
              }
              &:after {
                position: absolute;
                width: 6px;
                height: 6px;
                content: '';
                @include border-radius(50%);
                background-color: $theme-color-4;
                top: 6px;
                left: 0;
              }
            }
          }
        }
        .tags-item {
          margin-top: 35px;
          border-top: 1px solid rgba(127, 136, 151, 0.2);
          padding-top: 35px;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          @media #{$large_mobile} {
            display: block;
          }
          @media #{$small_mobile} {
            display: block;
          }
          ul {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            @media #{$small_mobile_one} {
              display: block;
            }
            li {
              margin-right: 5px;
              @media #{$small_mobile_one} {
                display: inline-block;
              }
              span {
                color: $theme-color;
                font-weight: $font-weight-semi-bold;
              }
            }
            &.tag__list {
              li {
                @media #{$small_mobile_one} {
                  margin-bottom: 15px;
                }
                a {
                  background-color: rgba(81, 190, 120, 0.1);
                  color: $theme-color-2;
                  @include border-radius(4px);
                  padding: 5px 12px;
                  @include transition(0.3s);
                  &:hover {
                    background-color: $theme-color-2;
                    color: $white;
                  }
                }
              }
            }
            &.social__links {
              margin-left: auto;
              @media #{$large_mobile} {
                margin-top: 20px;
              }
              @media #{$small_mobile} {
                margin-top: 20px;
              }
              li {
                a {
                  width: 35px;
                  height: 35px;
                  line-height: 35px;
                  text-align: center;
                  background-color: rgba(81, 190, 120, 0.1);
                  color: $theme-color-2;
                  @include border-radius(50%);
                  display: block;
                  @include transition(0.3s);
                  &:hover {
                    background-color: $theme-color-2;
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
      .instructor-wrap {
        .instructor-wrap__title {
          font-size: $display-10;
          font-weight: $font-weight-semi-bold;
        }
        .instructor-content {
          margin-top: 40px;
          margin-bottom: 40px;
          .instructor-img {
            .instructor__avatar {
              img {
                width: 150px;
                height: 150px;
                @include border-radius(50%);
                margin-right: 20px;
                @media #{$large_mobile} {
                  margin-right: 0;
                  margin-bottom: 20px;
                }
                @media #{$small_mobile} {
                  margin-right: 0;
                  margin-bottom: 20px;
                }
              }
            }
          }
          .instructor-details {
            .instructor-titles {
              margin-bottom: 20px;
              .instructor__title {
                font-size: $display-11;
                font-weight: $font-weight-semi-bold;
              }
            }
            .instructor-desc {
              .instructor__text {
                line-height: 28px;
                margin-bottom: 15px;
              }
              .instructor__links {
                color: $theme-color;
                @include transition(0.3s);
                &:hover {
                  color: $theme-color-2;
                }
              }
            }
          }
        }
        .instructor-content.d-flex {
          @media #{$large_mobile} {
            display: block !important;
          }
          @media #{$small_mobile} {
            display: block !important;
          }
        }
      }
      .comments-wrapper {
        .contact-title {
          margin-bottom: 35px;
        }
        .comments-list {
          li {
            .comment {
              .comment-body {
                .meta-data {
                  display: -webkit-flex;
                  display: -ms-flex;
                  display: flex;
                  -ms-flex-align: center;
                  align-items: center;
                  @media #{$large_mobile} {
                    display: block;
                  }
                  @media #{$small_mobile} {
                    display: block;
                  }
                  .comment__author {
                    margin-bottom: 0;
                  }
                  .comment__date {
                    margin-left: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/*-===============================
    TEAM DETAILS AREA
===============================-*/
.team-detail-area {
  padding-top: 120px;
  padding-bottom: 90px;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-bottom: 120px;
  }
  @media #{$small_mobile} {
    padding-bottom: 120px;
  }
  .team-single-img {
    position: relative;
    z-index: 1;
    @media #{$tab_device} {
      margin-bottom: 60px;
    }
    @media #{$large_mobile} {
      margin-bottom: 60px;
    }
    @media #{$small_mobile} {
      margin-bottom: 60px;
    }
    img {
      width: 100%;
      @include border-radius(15px 0 15px 15px);
    }
    &:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      left: -45px;
      bottom: -52px;
      z-index: -1;
      @include border-radius(4px);
      background-image: url("../images/dots.png");
      background-size: cover;
      background-position: center;
      opacity: 0.1;
    }
  }
  .team-single-content {
    padding-left: 30px;
    @media #{$tab_device} {
     padding-left: 0;
    }
    @media #{$large_mobile} {
      padding-left: 0;
    }
    @media #{$small_mobile} {
      padding-left: 0;
    }
    .tsd-box {
      .tsd-item {
        .tsdi__title {
          font-size: $display-9;
          font-weight: $font-weight-semi-bold;
          margin-bottom: 25px;
        }
        .tsdi__meta {
          font-size: $display-10;
          font-weight: $font-weight-medium;
        }
        .tsdi__desc {
          line-height: 30px;
          margin-top: 10px;
        }
        .tsdi__list {
          li {
            position: relative;
            font-weight: $font-weight-medium;
            margin-bottom: 10px;
            padding-left: 20px;
            &:last-child {
              margin-bottom: 0;
            }
            &:after {
              position: absolute;
              content: "";
              top: 6px;
              left: 0;
              width: 10px;
              height: 10px;
              @include border-radius(50%);
              background-color: rgba(127, 136, 151, 0.4);
            }
          }
        }
      }
      .education-detail {
        @media #{$tab_device} {
          margin-top: 35px;
        }
        @media #{$large_mobile} {
          margin-top: 35px;
        }
        @media #{$small_mobile} {
          margin-top: 35px;
        }
      }
    }
    .tsd-box-2 {
      margin-bottom: 33px;
    }
    .tsd-box-3 {
      .col-lg-4 {
        @media #{$tab_device} {
          -ms-flex: 0 0 33%;
          flex: 0 0 33%;
          max-width: 33%;
        }
        @media #{$large_mobile} {
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
     .tsd-item {
       @media #{$tab_device} {
         margin-bottom: 30px;
       }
       @media #{$large_mobile} {
         margin-bottom: 30px;
       }
       @media #{$small_mobile} {
         margin-bottom: 30px;
       }
       .tsdi__title {
         margin-bottom: 15px;
       }
     }
    }
    .ts__contact {
      margin-top: 34px;
      li {
        display: block;
        position: relative;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $theme-color-4;
          font-weight: $font-weight-medium;
          @include transition(0.3s);
          span {
            margin-right: 10px;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            color: $theme-color-2;
            @include border-radius(50%);
            @include transition(0.3s);
            position: relative;
            background-color: rgba(81,190,120,0.1);
          }
          &:hover {
            color: $theme-color-2;
            span {
              background-color: $theme-color-2;
              color: $white;
            }
          }
        }
      }
    }
  }
  .about-tab-wrap {
    margin-top: 60px;
    @media #{$tab_device} {
      margin-top: 30px;
    }
    @media #{$large_mobile} {
      margin-top: 30px;
    }
    @media #{$small_mobile} {
      margin-top: 30px;
    }
    .course-tab-list {
      border-bottom: 0;
      margin-bottom: 25px;
      li {
        margin-right: 5px;
        a {
          display: block;
          color: $theme-color-2;
          font-weight: $font-weight-medium;
          @include transition(0.3s);
          @include border-radius(4px);
          background-color: rgba(81, 190, 120, 0.1);
          padding: 8px 20px;
          &.active {
            background-color: $theme-color-2;
            color: $white;
          }
        }
      }
    }
    .tab-content {
     .pane-body {
       background-color: $white;
       padding: 30px 35px;
       @include border-radius(4px);
       @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
       line-height: 28px;
     }
    }
  }
  .instructor-all-course {
    margin-top: 60px;
    .instructor-all-course__title {
      margin-bottom: 40px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(127, 136, 151, 0.2);
      font-weight: $font-weight-semi-bold;
    }
    .col-lg-4 {
      @media #{$tab_device} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .student-all-course {
    margin-top: 100px;
  }
}
/*-===============================
    PACKAGE AREA
===============================-*/
.package-area {
  padding-top: 120px;
  padding-bottom: 90px;
  position: relative;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-bottom: 120px;
  }
  @media #{$small_mobile} {
    padding-bottom: 120px;
  }
  .package-content-wrap {
    .col-lg-4 {
      @media #{$tab_device} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    .package-item {
      background-color: $white;
      @include box-shadow(0 0 40px rgba(14, 16, 48, 0.05));
      @include border-radius(4px);
      padding: 42px 40px 45px 40px;
      margin-bottom: 30px;
      position: relative;
      z-index: 1;
      @media #{$small_mobile} {
        width: auto;
      }
      .package-tooltip {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include border-radius(4px 4px 0 0);
        .package__tooltip {
          background-color: $theme-color-2;
          color: $white;
          font-weight: $font-weight-semi-bold;
          text-align: center;
          display: block;
          @include border-radius(4px 4px 0 0);
          padding: 15px 0;
          font-size: $display-13;
          position: relative;
          z-index: 1;
          &:after {
           @extend %theme-btn-after;
          }
        }
      }
      .package-title {
        border-bottom: 1px solid rgba(127, 136, 151, 0.1);
        padding-bottom: 33px;
        margin-bottom: 35px;
        .package__price {
          font-size: $display-5;
          font-weight: $font-weight-semi-bold;
          margin-bottom: 10px;
        }
        .package__title {
          font-size: $display-10;
          font-weight: $font-weight-semi-bold;
          margin-bottom: 6px;
        }
      }
      .package-list {
        padding-bottom: 33px;
        li {
          font-weight: $font-weight-medium;
          margin-bottom: 15px;
          span {
            color: $alter-color-4;
            font-size: $default-font-2;
            margin-right: 2px;
            &.la-close {
              color: $alter-color-5;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .package-price {
        .theme-btn {
          display: block;
          width: 100%;
          text-align: center;
          background-color: transparent;
          border: 1px solid $theme-color-2;
          color: $theme-color-2;
          &:hover {
            background-color: $theme-color-2;
            color: $white;
          }
        }
        .package__meta {
          text-transform: uppercase;
          letter-spacing: 2px;
          font-weight: $font-weight-medium;
          margin-top: 23px;
          font-size: $default-font-2;
        }
      }
    }
    .package-item2 {
      padding-top: 95px;
      margin-top: -24px;
      @media #{$tab_device} {
        margin-top: 0;
      }
      @media #{$large_mobile} {
        margin-top: 0;
      }
      @media #{$small_mobile} {
        margin-top: 0;
      }
      .package-price {
        .theme-btn {
          background-color: $theme-color-2;
          color: $white;
        }
      }
    }
  }
}

/*-===============================
    PAGINATION-WRAP
===============================-*/
.pagination-wrap {
  margin-top: 25px;
  .pagination {
    .page-item {
      .page-link {
        color: $theme-color-2;
        background-color: rgba(81, 190, 120, 0.1);;
        font-size: $display-13 ;
        margin-left: 3px;
        @include border-radius(50%);
        border: none;
        width: 40px;
        height: 40px;
        line-height: 39px;
        text-align: center;
        padding: 0;
      }
      &:hover .page-link,
      &.active .page-link {
        background-color: $theme-color-2;
        color: $white;
      }
    }
  }
}
/*-===============================
    SIDEBAR
===============================-*/
.sidebar {
  position: relative;
  @media #{$tab_device} {
    margin-top: 60px;
  }
  @media #{$large_mobile} {
    margin-top: 60px;
  }
  @media #{$small_mobile} {
    margin-top: 60px;
  }
  .sidebar-widget {
    .contact-form-action {
      form {
        .form-group {
          position: relative;
          margin-bottom: 0;
          .form-control {
            padding-left: 20px;
          }
          .search__btn {
            position: absolute;
            top: 50%;
            right: 20px;
            @include transform(translateY(-50%));
            border: none;
            background-color: transparent;
            color: $theme-color;
            font-size: $display-13;
            outline: none;
            @include transition(0.3s);
          }
          .subscriber__btn {
            background-color: $theme-color-2;
            width: 40px;
            height: 40px;
            line-height: 40px;
            right: 15px;
            color: $white;
            font-size: $display-12;
            @include border-radius(50%);
            &:hover {
              background-color: $theme-color;
            }
          }
        }
      }
    }
    margin-bottom: 30px;
    border: 1px solid rgba(127, 136, 151, 0.2);
    @include border-radius(4px);
    padding: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .widget__title {
      font-weight: $font-weight-semi-bold;
      font-size: $display-10;
      margin-bottom: 10px;
    }
    .section__divider {
      display: inline-block;
      position: relative;
      height: 4px;
      @include border-radius(2px);
      background-color: $theme-color-2;
      width: 50px;
      margin-bottom: 18px;
      overflow: hidden;
      &:after {
        content: '';
        position: absolute;
        left: 15px;
        top: -1.1px;
        height: 7px;
        width: 8px;
        background-color: $white;
      }
    }
    .widget__list {
      li {
        display: block;
        margin-bottom: 12px;
        position: relative;
        padding-left: 20px;
        &:after {
          position: absolute;
          content: "";
          top: 6px;
          left: 0;
          width: 10px;
          height: 10px;
          @include border-radius(50%);
          border: 1px solid rgba(127, 136, 151, 0.5);
          @include transition(0.3s);
        }
        &:last-child {
          margin-bottom: 0;
        }
        a {
          display: block;
          color: $theme-color-4;
          text-transform: capitalize;
          font-weight: $font-weight-medium;
          @include transition(0.3s);
          &:hover {
            color: $theme-color-2;
          }
        }
        &:hover {
          &:after {
            background-color: $theme-color-2;
            border-color: $theme-color-2;
          }
        }
      }
    }
    .recent-item {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(127, 136, 152, 0.1);
      @media #{$small_mobile_four} {
        display: block;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
      .recent-img {
        width: 85px;
        height: 100px;
        margin-right: 15px;
        flex-shrink: 0;
        @media #{$small_mobile_four} {
          margin-right: 0;
          margin-bottom: 15px;
        }
        img {
          width: 100%;
          height: 100%;
          @include border-radius(4px);
        }
      }
      .recentpost-body {
        .recent__meta {
          a {
            color: $theme-color-4;
            @include transition(0.3s);
            &:hover {
              color: $theme-color-2;
            }
          }
        }
        .recent__link {
          font-size: $default-font;
          font-weight: $font-weight-semi-bold;
          display: block;
          margin-top: 5px;
          margin-bottom: 5px;
          a {
            color: $theme-color;
            @include transition(0.3s);
            &:hover {
              color: $theme-color-2;
            }
          }
        }
        .recent-course__price {
          color: $theme-color;
          font-weight: $font-weight-medium;
          span {
            text-decoration: line-through;
            margin-left: 3px;
            color: $theme-color-4;
          }
        }
      }
      .button-shared {
        width: 100%;
        text-align: center;
        .theme-btn {
          display: block;
          width: 100%;
          line-height: 50px;
          padding-right: 20px;
          padding-left: 20px;
          background-color: $white;
          color: $theme-color;
          border: 1px solid rgba(127, 136, 152, 0.2);
          &:hover {
            background-color: $theme-color-2;
            color: $white;
            border-color: $theme-color-2;
          }
        }
      }
    }
    .social__links {
      li {
        display: inline-block;
        a {
          color: $theme-color-2;
          display: block;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          @include border-radius(50%);
          background-color: rgba(81, 190, 120, 0.1);
          @include transition(0.3s);
          &:hover {
            background-color: $theme-color-2;
            color: $white;
          }
        }
      }
    }
    .rating__list {
      margin-top: 0;
      margin-bottom: 0;
      li {
        display: block;
        padding-right: 0;
        padding-bottom: 10px;
        .review-label {
          display: inline-block;
          margin-top: 4px;
          float: left;
          margin-right: 28px;
        }
      }
    }
    .courses-ordering {
      position: relative;
      .target-course {
        width: 100%;
        border: 1px solid rgba(127, 136, 151, 0.2);;
        padding: 15px 0 15px 20px;
        font-size: $default-font;
        color: $theme-color-4;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        cursor: pointer;
        outline: none;
        @include border-radius(4px);
        background-color: transparent;
        font-weight: $font-weight-medium;
      }
      &:before {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        font-family: "LineAwesome";
        font-size: $default-font-2;
        color: $theme-color-4;
        pointer-events: none;
        display: inline-block;
        content: "\f110";
        right: 13px;
      }
    }
  }
  .tag-widget {
    .widget__list {
      li {
        padding-left: 0;
        display: inline-block;
        &:after {
          display: none;
        }
        a {
          border: 1px solid rgba(127, 136, 152, 0.2);
          padding: 4px 14px;
          @include border-radius(4px);
          display: block;
          &:hover {
            background-color: $theme-color-2;
            color: $white;
            border-color: $theme-color-2;
          }
        }
      }
    }
  }
  .instructor-widget {
    .button-shared {
      width: 100%;
      text-align: center;
      .theme-btn {
        display: block;
        width: 100%;
        line-height: 50px;
        padding-right: 20px;
        padding-left: 20px;
        background-color: $white;
        color: $theme-color;
        border: 1px solid rgba(127, 136, 152, 0.2);
        @media #{$small_mobile_three} {
          padding-right: 15px;
          padding-left: 15px;
        }
        &:hover {
          background-color: $theme-color-2;
          color: $white;
          border-color: $theme-color-2;
        }
      }
    }
  }

}

/*== rating__list ==*/
.rating__list {
  margin-top: 25px;
  margin-bottom: 45px;
  li {
    display: inline-block;
    padding-right: 20px;
    @media #{$small_mobile_two} {
      display: block;
      padding-right: 0;
      padding-bottom: 30px;
    }
    .la-star {
      color: $alter-color-2;
      font-size: $default-font;
    }
    &:last-child {
      padding-right: 0;
    }
    .review-label {
      display: block;
      position: relative;
      user-select: none;
      margin-top: 5px;
      input {
        opacity: 0;
        position: absolute;
        cursor: pointer;
        &:checked ~ .review-mark {
          border-color: $alter-color-2;
          &:before {
            opacity: 1;
            @include transform(scale(1));
          }
        }
      }
      .review-mark {
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        cursor: pointer;
        border-width: 2px;
        border-style: solid;
        border-color: $color;
        border-image: initial;
        @include border-radius(50%);
        @include transition(0.3s);
        &:before {
          top: 2px;
          left: 2px;
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          background-color: $alter-color-2;
          opacity: 0;
          @include transform(scale(0.1));
          @include border-radius(50%);
          @include transition(0.3s);
        }
      }
    }
  }
}
/*-===============================
    CONTACT
===============================-*/
.contact-area {
  padding-top: 120px;
  padding-bottom: 120px;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-bottom: 120px;
  }
  @media #{$small_mobile} {
    padding-bottom: 120px;
  }
  .contact-item {
    background-color: $white;
    padding: 35px 30px;
    @include box-shadow(0 0 40px rgba(14, 16, 48, 0.05));
    @include border-radius(4px);
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    overflow: hidden;
    @include transition(0.3s);
    z-index: 2;
    .hover-overlay {
      &:before,
      &:after {
        position: absolute;
        content: '';
        top: 50%;
        left: -90px;
        width: 540px;
        height: 540px;
        z-index: -1;
        transition: all .5s ease;
        @include transform(translateY(100%) rotate(10deg));
        background-color: rgba(255, 255, 255, 0.2);
      }
      &:after {
        top: 70%;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    .contact__icon {
      color: $alter-color;
      font-size: $display-5;
      position: relative;
      width: 80px;
      height: 80px;
      line-height: 80px;
      @include border-radius(50%);
      background-color: rgba(126, 60, 249, 0.1);
      text-align: center;
      @include transition(0.3s);
    }
    .contact__title {
      font-weight: $font-weight-semi-bold;
      font-size: $display-10;
      position: relative;
      margin-top: 23px;
      margin-bottom: 20px;
      @include transition(0.3s);
    }
    .contact__desc {
      line-height: 28px;
      @include transition(0.3s);
    }
    .contact__list {
      li {
        margin-bottom: 10px;
        font-weight: $font-weight-medium;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $theme-color-4;
          @include transition(0.3s);
        }
        &:hover {
          a {
            color: $theme-color-2;
          }
        }
      }
    }
    &:before {
      position: absolute;
      content: '';
      top: 30%;
      left: -90px;
      width: 540px;
      height: 540px;
      background-color: rgba(255, 255, 255, 0.1);
      z-index: -1;
      transition: all .5s ease;
      @include transform(translateY(100%) rotate(10deg));
    }
    &:hover {
      border-color: $theme-color-2;
      background-color: $theme-color-2;
      .contact__title,
      .contact__desc,
      .contact__list li a {
        color: $white;
      }
      .contact__icon {
        color: $theme-color-2;
        background-color: $white;
      }
      &:before {
        transition-delay: .2s;
        @include transform(translateY(0) rotate(10deg));
      }
      .hover-overlay {
        &:before,
        &:after {
          @include transform(translateY(0) rotate(10deg));
        }
        &:after {
          transition-delay: .1s;
        }
      }
    }
  }
  .contact-item2 {
    .contact__icon {
      color: $alter-color-2;
      background-color: rgba(246, 138, 3, 0.2);
    }
  }
  .contact-item3 {
    .contact__icon {
      color: $alter-color-3;
      background-color: rgba(53, 143, 247, 0.2);
    }
  }
  .contact-form-wrap {
    margin-top: 90px;
    .section-heading {
      @media #{$tab_device} {
        margin-bottom: 60px;
      }
      @media #{$large_mobile} {
        margin-bottom: 60px;
      }
      @media #{$small_mobile} {
        margin-bottom: 60px;
      }
      .section__title {
        @media #{$small_mobile_three} {
          font-size: $display-7;
        }
      }
      .section__list {
        li {
          display: inline-block;
          a {
            color: $theme-color-2;
            i {
              width: 35px;
              height: 35px;
              line-height: 35px;
              text-align: center;
              @include border-radius(50%);
              background-color: rgba(81, 190, 120, 0.1);
              @include transition(0.3s);
            }
            &:hover {
              i {
                background-color: $theme-color-2;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
/*-===============================
    MPA AREA
===============================-*/
.map-container #map {
  width: 100%;
  height: 500px;
  @media #{$device-481} {
    height: 400px;
  }
}
.map-container #map .gm-style .gmnoprint,
.map-container #map .gm-style button.gm-control-active.gm-fullscreen-control,
.map-container #map .gm-style .gm-style-cc {
  display: none;
}

/*-===============================
    SUBSCRIBER AREA
===============================-*/
.subscriber-area {
  padding-top: 115px;
  padding-bottom: 100px;
  position: relative;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-bottom: 120px;
  }
  @media #{$small_mobile} {
   padding-bottom: 120px;
  }
  .section-heading {
    @media #{$tab_device} {
     text-align: center;
    }
    @media #{$large_mobile} {
      text-align: center;
    }
    @media #{$small_mobile} {
      text-align: center;
    }
  }
  .subscriber-form-wrap {
    height: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 1;
    .subscriber-form {
      position: relative;
      width: 100%;
      @media #{$small_mobile_four} {
       text-align: center;
      }
      .form-control {
        height: auto;
        line-height: inherit;
        padding: 20px 20px 20px 50px;
        font-size: $default-font;
        color: $theme-color;
        border-color: rgba(127, 136, 151, 0.2);
        font-weight: $font-weight-regular;
        background-color: $white;
        @include box-shadow(0 0 40px rgba(14,16,48,.06));
        @include transition(0.3s);
        @include border-radius(4px);
        &:focus {
          border-color:rgba(127, 136, 151, 0.4);
        }
      }
      .la-envelope-o {
        position: absolute;
        top: 22px;
        left: 20px;
        font-size: $display-13;
      }
      .theme-btn {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        right: 8px;
        line-height: 50px;
        @media #{$small_mobile_four} {
          position: inherit;
          top: auto;
          right: auto;
          @include transform(translateY(0));
          margin-top: 20px;
        }
      }
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
    top: -100px;
    left: 30px;
    width: 200px;
    height: 200px;
    background-position: center;
    background-image: url("../images/dots.png");
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
    @include border-radius(50%);
  }
  &:after {
    top: auto;
    left: auto;
    bottom: 0;
    right: 30px;
  }
}
/*-===============================
    SUBSCRIBER AREA 2
===============================-*/
.subscriber-area2 {
  .section-heading {
    margin-bottom: 35px;
    .section__title {
      width: 700px;
      margin: 0 auto;
      @media #{$tab_device} {
        width: auto;
      }
      @media #{$large_mobile} {
        width: auto;
      }
      @media #{$small_mobile} {
        width: auto;
      }
    }
  }
  .subscriber-form-wrap {
    .subscriber-form {
      .form-control {
        @include border-radius(30px);
      }
    }
  }
}
/*======== contact-form-action =========*/
.contact-form-action {
  form {
    .form-group {
      margin-bottom: 20px;
      position: relative;
      .course-filter {
        .courses-ordering {
          width: 100%;
        }
      }
      .birthday-filter {
        .courses-ordering {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          @media #{$tab_device} {
            display: block;
          }
          @media #{$large_mobile} {
            display: block;
          }
          @media #{$small_mobile} {
            display: block;
          }
          &:before {
            display: none;
          }
          span {
            width: 153px;
            margin-right: 5px;
            position: relative;
            @media #{$tab_device} {
              width: 100%;
              margin-right: 0;
            }
            @media #{$large_mobile} {
              width: 100%;
              margin-right: 0;
            }
            @media #{$small_mobile} {
              width: 100%;
              margin-right: 0;
            }
            &:before {
              position: absolute;
              top: 50%;
              @include transform(translateY(-50%));
              font-family: "LineAwesome";
              font-size: $default-font-2;
              color: $theme-color-4;
              pointer-events: none;
              display: inline-block;
              content: "\f110";
              right: 13px;
            }
            &:nth-of-type(3) {
              margin-right: 0;
            }
            .target-course {
              @media #{$tab_device} {
                margin-bottom: 15px;
              }
              @media #{$large_mobile} {
                margin-bottom: 15px;
              }
              @media #{$small_mobile} {
                margin-bottom: 15px;
              }
            }
          }
        }
      }
      .form-label {
        color: $theme-color;
        text-transform: capitalize;
        font-weight: $font-weight-medium;
      }
      .gender-control {
        margin-right: 5px;
        &:nth-of-type(2) {
          margin-right: 0;
        }
      }
      .form-control {
        height: auto;
        width: 100%;
        line-height: inherit;
        padding: 15px 20px 15px 50px;
        font-size: $default-font;
        color: $theme-color;
        border-color: rgba(127,136,151,0.2);
        font-weight: $font-weight-regular;
        background-color: $white;
        resize: none;
        @include box-shadow(0 0 0 0);
        @include transition(0.3s);
        @include border-radius(4px);
        &:focus {
          border-color: rgba(127,136,151,0.5);
        }
      }
      .input-icon {
        position: absolute;
        top: 18px;
        left: 20px;
        font-size: $display-13;
      }
      .search-icon {
        position: absolute;
        top: 50%;
        right: 16px;
        @include transform(translateY(-50%) rotate(-90deg));
        font-size: $display-13;
        cursor: pointer;
        padding: 17px;
      }
      .message-control {
        height: 150px;
      }
    }
    .gender-group {
      margin-bottom: 30px;
      .form-label {
        margin-right: 8px;
      }
    }
    .theme-btn {
      outline: none;
    }
  }
}

/*====================================================
    form-shared
 ====================================================*/
.form-shared {
  padding-top: 120px;
  padding-bottom: 90px;
  position: relative;
  z-index: 1;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-bottom: 120px;
  }
  @media #{$small_mobile} {
    padding-bottom: 120px;
  }
  .contact-form-action {
    background-color: $white;
    @include border-radius(4px);
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    padding: 46px 30px 42px 30px;
    border: 1px solid $color;
    .form-heading {
      margin-bottom: 33px;
      .form__title {
        font-size: $display-6;
        color: $theme-color;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 8px;
        @media #{$small_mobile_three} {
          font-size: $display-7;
        }
      }
      .form__desc {
        font-size: $display-13;
        color: $theme-color-4;
        font-weight: $font-weight-medium;
      }
      .reset__desc {
        line-height: 30px;
        margin-top: 15px;
        a {
          color: $theme-color-2;
        }
      }
    }
    form {
      .form-group {
        .sign-btn {
          width: 100%;
          @media #{$device-992} {
            padding: 0;
          }
          @media #{$device-767} {
            padding: 0;
          }
        }
        .btn__google {
          background-color: $alter-color-5;
        }
        .btn__facebook {
          background-color: $alter-color-6;
        }
        .btn__twitter {
          background-color: $alter-color-7;
        }
        .reset__btn {
          margin-top: 0;
        }
      }
      .form-condition {
        margin-bottom: 25px;
      }
      .account-assist {
        margin-top: 15px;
        .account__desc {
          font-size: $default-font;
          color: $theme-color-4;
          font-weight: $font-weight-medium;
          a {
            color: $theme-color-2;
            &:hover {
              color: $theme-color-3;
            }
          }
          &.text-right {
            @media #{$large_mobile} {
              text-align: left !important;
            }
            @media #{$small_mobile} {
              text-align: left !important;
            }
          }
        }
        .account__desc2 {
          font-size: $display-12;
          position: relative;
          margin-bottom: 32px;
          &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            width: 45%;
            height: 1px;
            background-color: $color-2;
            @include transform(translateY(-50%));
          }
          &:after {
            position: absolute;
            content: '';
            right: 0;
            top: 50%;
            width: 45%;
            height: 1px;
            background-color: $color-2;
            @include transform(translateY(-50%));
          }
        }
      }
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
    top: 55px;
    right: 55px;
    width: 200px;
    height: 200px;
    background-image: url(../images/dots.png);
    background-position: center;
    background-size: cover;
    z-index: -1;
    @include border-radius(50%);
    opacity: 0.3;
  }
  &:after {
    bottom: 55px;
    left: 55px;
    right: auto;
    top: auto;
  }
}
/*-===============================
    custom-checkbox
===============================-*/
.custom-checkbox {
  margin-bottom: 8px;
  @media #{$small_mobile_four} {
    margin-bottom: 0;
  }
  label {
    color: $theme-color-4;
    font-size: $default-font;
    font-weight: $font-weight-medium;
    a {
      color: $theme-color-2;
      @include transition(0.3s);
      &:hover {
        color: $theme-color-3;
        text-decoration: underline;
      }
    }
  }
  .pass__desc {
    color: $theme-color-2;
    font-weight: $font-weight-semi-bold;
    float: right;
    @media #{$small_mobile_four} {
      float: none;
      text-align: left;
      display: block;
      margin-top: 5px;
    }
    &:hover {
      color: $theme-color-3;
    }
  }
  input[type=checkbox]:not(:checked),
  input[type=checkbox]:checked {
    position: absolute;
    left: -9999px;
  }
  input[type=checkbox]:not(:checked) + label,
  input[type=checkbox]:checked + label {
    position: relative;
    padding-left: 26px;
    cursor: pointer;
  }
  input[type=checkbox]:not(:checked) + label:before,
  input[type=checkbox]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(127, 136, 151, 0.4);
    background-color: $white;
    @include border-radius(2px);
  }
  input[type=checkbox]:not(:checked) + label:after,
  input[type=checkbox]:checked + label:after {
    content: '\f00c';
    position: absolute;
    top: 1px;
    left: 3px;
    font-size: $default-font-4;
    color: $white;
    @include transition(0.3s);
    font-family: FontAwesome,sans-serif;
  }
  input[type=checkbox]:not(:checked) + label:after {
    opacity: 0;
    visibility: hidden;
  }
  input[type=checkbox]:checked + label:after {
    opacity: 1;
    visibility: visible;
  }
  input[type=checkbox]:checked + label:before {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
  }
}

/*-===============================
    FOOTER AREA
===============================-*/
.footer-area {
  position: relative;
  padding-bottom: 40px;
  padding-top: 160px;
  z-index: 1;
  overflow: hidden;
  @media #{$tab_device} {
    padding-top: 345px;
  }
  @media #{$large_mobile} {
    padding-top: 120px;
    background-color: $theme-color;
  }
  @media #{$small_mobile} {
    padding-top: 120px;
    background-color: $theme-color;
  }
  .ocean {
    height: 67%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $theme-color;
    @media #{$large_mobile} {
      display: none;
    }
    .wave {
      background: url(../images/wave.svg) repeat-x;
      position: absolute;
      top: -198px;
      width: 6400px;
      height: 350px;
      -webkit-animation: wave 55s cubic-bezier(0.36, 0.45, 0.63, 0.93) infinite;
      animation: wave 55s cubic-bezier(0.36, 0.45, 0.63, 0.93) infinite;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      @media #{$tab_device} {
        height: 230px;
      }
      &:nth-of-type(2) {
        top: -175px;
        -webkit-animation: wave 55s cubic-bezier(0.36, 0.45, 0.63, 0.93) -0.125s infinite, swell 55s ease -1.25s infinite;
        animation: wave 55s cubic-bezier(0.36, 0.45, 0.63, 0.93) -0.125s infinite, swell 55s ease -1.25s infinite;
        opacity: 1;
      }
    }
  }
  .footer-widget {
    @media #{$tab_device} {
      margin-bottom: 40px;
    }
    @media #{$large_mobile} {
      margin-bottom: 40px;
    }
    @media #{$small_mobile} {
      margin-bottom: 40px;
    }
    .footer__logo {
      width: 150px;
    }
    .footer-title {
      text-transform: capitalize;
      font-weight: $font-weight-semi-bold;
      position: relative;
      margin-bottom: 20px;
      font-size: $display-10;
      color: $white;
    }
    .section__divider {
      display: inline-block;
      position: relative;
      height: 5px;
      @include border-radius(30px);
      background-color: $theme-color-7;
      width: 50px;
      margin-bottom: 32px;
      overflow: hidden;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: -0.1px;
        height: 5px;
        width: 5px;
        background-color: $white;
        -webkit-animation-duration: 3s;
        animation-duration: 3s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-name: MOVE-BG2;
        animation-name: MOVE-BG2;
      }
    }
    a {
      color: $theme-color-7;
      font-weight: $font-weight-medium;
      text-transform: capitalize;
      @include transition(0.3s);
      &:hover {
        color: $theme-color-2;
      }
    }
    .footer-address {
      margin-top: 38px;
      line-height: 30px;
      font-weight: $font-weight-medium;
      li {
        display: block;
        color: $theme-color-7;
        line-height: 24px;
        margin-bottom: 7px;
      }
    }
    .footer-social {
      margin-top: 25px;
      li {
        display: inline-block;
        margin-right: 2px;
        &:last-child {
          margin-right: 0;
        }
        a {
          display: block;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          @include border-radius(10px);
          background-color: rgba(158, 166, 186, 0.1);
          &:hover {
            background-color: $theme-color-2;
            color: $white;
          }
        }
      }
    }
    .footer-link {
      li {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          position: relative;
          padding-left: 15px;
          &:after {
            position: absolute;
            content: '';
            top: 9px;
            left: 0;
            width: 5px;
            height: 2px;
            background-color: $theme-color-7;
            @include transition(0.3s);
          }
          i {
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            @include border-radius(50%);
            background-color: rgba(63, 65, 97, 0.1);
            @include transition(0.3s);
            font-size: $default-font-2;
            margin-right: 5px;
          }
          &:hover {
            &:after {
              width: 10px;
              background-color: $theme-color-2;
            }
            i {
              color: $white;
            }
          }
        }
      }
    }
    .social-link {
      text-align: left;
      li {
        margin-bottom: 15px;
        a {
          padding: 20px 20px 20px 60px;
          display: block;
          background-color: rgba(158, 166, 186, 0.1);
          @include border-radius(4px);
          font-size: $display-11;
          position: relative;
          @media #{$large_mobile_three} {
            display: inline-block;
            width: 210px;
          }
          i {
            left: 20px;
            top: 15px;
            font-size: $display-7;
            position: absolute;
            width: auto;
            height: auto;
            @include border-radius(0);
            background-color: transparent;
          }
          &:hover {
            background-color: $white;
            i {
              color: $theme-color-2;
            }
          }
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .copyright-content {
    margin-top: 90px;
    padding-top: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    @media #{$tab_device} {
      margin-top: 40px;
      text-align: center;
    }
    @media #{$large_mobile} {
      margin-top: 40px;
      text-align: center;
    }
    @media #{$small_mobile} {
      margin-top: 40px;
      text-align: center;
    }
    .copy__desc {
      color: $theme-color-7;
      @media #{$tab_device} {
       margin-bottom: 30px;
      }
      @media #{$large_mobile} {
       margin-bottom: 30px;
      }
      @media #{$small_mobile} {
       margin-bottom: 30px;
      }
      a {
        color: $theme-color-7;
        @include transition(0.3s);
        &:hover {
          color: $theme-color-2;
        }
      }
    }
    .language-select {
      text-align: right;
      position: relative;
      @media #{$tab_device} {
        width: 30%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
      @media #{$large_mobile} {
        width: 33%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
      @media #{$small_mobile} {
        width: 45%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
      .target-lang {
        width: 100%;
        border: 1px solid rgba(255, 255, 255, 0.2);
        padding: 10px 0 10px 35px;
        font-size: 16px;
        color: $theme-color-7;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        cursor: pointer;
        outline: none;
        @include border-radius(4px);
        background-color: transparent;
        font-weight: $font-weight-medium;
      }
      &:before,
      &:after {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        font-family: "LineAwesome";
        font-size: $default-font-2;
        color: $theme-color-7;
        pointer-events: none;
        display: inline-block;
      }
      &:before {
        content: "\f113";
        right: 13px;
      }
      &:after {
        content: "\f219";
        left: 13px;
      }
    }
  }
}

/*=== wave ===*/
@-webkit-keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@-webkit-keyframes swell {
  0%, 100% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
}
@keyframes swell {
  0%, 100% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
}

/*=== scroll-top ===*/
#scroll-top {
  position: fixed;
  right: 0;
  bottom: 50px;
  background-color: $white;
  color: $theme-color-4;
  font-size: $display-10;
  z-index: 9999;
  width: 50px;
  height: 45px;
  line-height: 43px;
  @include border-radius(30px 0 0 30px);
  text-align: center;
  cursor: pointer;
  @include box-shadow(0 10px 40px rgba(14,16,48,.06));
  border: 1px solid rgba(127,136,151,0.3);
  border-right: 0;
  padding-left: 4px;
  @include transition(0.3s);
  &:hover {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
    color: $white;
  }
}

