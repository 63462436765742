$theme-color: #233d63;
$theme-color-2: #51be78;
$theme-color-3: #42be6f;
$theme-color-4: #7f8897;
$theme-color-5: #F7FAFD;
$theme-color-6: #0e0e1e;
$theme-color-7: #9ea6ba;
$white: #fff;
$black: #000;
$color: #eee;
$theme-font: 'Libre Franklin', sans-serif;
$default-font: 16px;
$default-font-2: 15px;
$default-font-3: 14px;
$default-font-4: 13px;
$default-font-5: 12px;
$primary-font: 45px;
$display: 90px;
$display-2: 80px;
$display-3: 70px;
$display-4: 60px;
$display-5: 50px;
$display-6: 40px;
$display-7: 30px;
$display-8: 28px;
$display-9: 26px;
$display-10: 24px;
$display-11: 22px;
$display-12: 20px;
$display-13: 18px;
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 800;
$alter-color: #7E3CF9;
$alter-color-2: #F68A03;
$alter-color-3: #358FF7;
$alter-color-4: #38BB0C;
$alter-color-5: #DD493D;
$alter-color-6: #3E5B99;
$alter-color-7: #3AAAE1;
$color: #eee;
$color-2: #BDBBBC;
/*---- theme-btn ----*/
%theme-btn {
  font-size: 15px;
  text-transform: uppercase;
  background-color: $theme-color-2;
  color: #fff;
  font-weight: $font-weight-medium;
  letter-spacing: 0.1px;
  padding: 0 30px 0 30px;
  line-height: 55px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  display: inline-block;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  border: none;
  overflow: hidden;
}
/*---- theme-btn-after ----*/
%theme-btn-after {
  position: absolute;
  left: -12px;
  top: 0;
  height: 100%;
  width: 56%;
  content: "";
  background-color: $white;
  opacity: .1;
  -webkit-transform: skewX(15deg);
  -moz-transform: skewX(15deg);
  -ms-transform: skewX(15deg);
  -o-transform: skewX(15deg);
  transform: skewX(15deg);
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
